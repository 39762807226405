import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import map from 'lodash/map';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { filter } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import HealthIcon from './HealthIcon';
import { KPI_BUTTONS } from './constants';
import clsx from 'clsx';
import { addTrendKpi, removeSelectedKpi } from '../../actions';
import { Checkbox } from 'sharedComponents/Checkbox';
import { SearchBox } from 'sharedComponents/SearchBox';
import { IconCondition } from 'svgIcons/MotionPortalIcons';
import { limitIndications } from 'helpers/constants';
import useUserCapability from 'sharedHooks/useUserCapability';
import { setIsDefaultLimitsSelected } from '../../LimitSelector/actions';
import { GeneralButton } from 'sharedComponents/GeneralButton';

const ParameterSection = (props) => {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const { section, capability, selectedTrendKPIs, dateSelection, selectedTrendsData, setShowLimitSetting, setClickedConfigKpi } = props;
    const userCapabilities = useUserCapability();
    const [searchboxFilter, setSearchboxFilter] = useState('');
    const dispatch = useDispatch();
    if (!section || !section.data || isEmpty(section.data)) {
        return null;
    }
    const { data } = section;
    let kpiData = data;
    const serchBoxValue = searchboxFilter.toLowerCase();
    if (serchBoxValue !== '' && serchBoxValue !== null) {
        kpiData = data?.filter((kpi) => {
            return kpi?.label?.toLowerCase().includes(serchBoxValue);
        });
    }
    const toggleMeasurement = (kpi, isSelected) => {
        if (isSelected) {
            dispatch(removeSelectedKpi(kpi));
        }
        else {
            console.log('The Selected KPI Value', kpi);
            console.log('The Old Selected KPI List', selectedTrendKPIs);
            dispatch(addTrendKpi(kpi));
        }
        dispatch(setIsDefaultLimitsSelected(false));
    };

    const handleSearchChange = (searchTerm) => {
        if (searchTerm.length <= 50) {
            setSearchboxFilter(searchTerm);
        }
    };

    // SET TREND KPI FROM NOTIFICATION
    useEffect(() => {
        if (history?.location?.state?.notificationData && kpiData?.length) {
            const kpi = history?.location?.state?.notificationData?.properties;
            dispatch(addTrendKpi(kpi));
        }
    }, [history?.location?.state, kpiData]);

    return <Col xl lg={12} className={clsx('measurement-list', !userCapabilities?.hasOperationalParametersMinMax() && 'measurement-list-capability')}>
        <div className='measurement-list-title'>
            {/* <div className='measurement-list-buttons'>
                <div>{translate('ABB.Powertrain.Frontend.detailedInformationOperationalParametersTab')}</div>
                {
                    map(SECTION_BUTTONS, (SectionButton, index) => {
                        return <SectionButton section={section} capability={capability} key={index} />;
                    })
                }
            </div> */}
            <div className='signal-group-header-info'>
                <div className='kpisignal__filter__search me-2'>
                    <SearchBox
                        placeholder={translate('ABB.Powertrain.Frontend.searchSignal')}
                        onChange={handleSearchChange}
                        value={searchboxFilter} />
                </div>
                <span>{selectedTrendKPIs.length}/{kpiData?.length}{' '}{translate('ABB.Powertrain.Frontend.selected')}</span>
            </div>
            <div className='limit-settings-button-wrapper'>
                <div className='kpisignal__filter__search'>
                    <GeneralButton
                        type='normal'
                        className='limit-config-Cancel-button'
                        text={translate('ABB.Powertrain.Frontend.limitsSetting')}
                        onClick={() => { setShowLimitSetting(true); }}
                    />
                </div>
            </div>
        </div>
        {<div className='measurement-list-head'>
            <div className='measurement-list-item'>
                <div className='data-command-checkbox'></div>
                <div className='data-label'>{translate('ABB.Powertrain.Frontend.operationalParametersNameLabel')}</div>
                { userCapabilities?.hasOperationalParametersMinMax() && <>
                    <div className='data-min-max'>
                        {translate('ABB.Powertrain.Frontend.operationalParametersMinLabel')}/{translate('ABB.Powertrain.Frontend.operationalParametersMaxLabel')}
                    </div>
                    <div className='data-average'>
                        {translate('ABB.Powertrain.Frontend.operationalParametersAverageLabel')}
                    </div>
                </> }
                <div className='data-value'>{translate('ABB.Powertrain.Frontend.opertaionalParametersLastMeasurementLabel')}</div>
                <div className='data-command'></div>
            </div>
        </div>}
        <div className='measurement-list-body'>
            {map(kpiData, (kpi) => {
                const isSelected = !!find(selectedTrendKPIs, (selectedTrendKPI) => kpi.timeseriesKey === selectedTrendKPI);
                const selectedTrendKpi = filter(selectedTrendsData, (selectedTrendData) => selectedTrendData?.kpiIdentifier === kpi?.timeseriesKey);
                const hasLengthSelectedTrendKpi = selectedTrendKpi?.length;
                const minValue = hasLengthSelectedTrendKpi && selectedTrendKpi[0]?.minValue;
                const maxValue = hasLengthSelectedTrendKpi && selectedTrendKpi[0]?.maxValue;
                const avgValue = hasLengthSelectedTrendKpi && selectedTrendKpi[0]?.avgValue;
                const offset = hasLengthSelectedTrendKpi && selectedTrendKpi[0]?.offset || 0;
                return <div className={clsx('measurement-list-item', isSelected && 'selected-measurement-list-item')}
                    onClick={() => kpi?.isPlottableToTrendChart && toggleMeasurement(kpi, isSelected)}
                    key={kpi.label}>
                    <div className='data-command-checkbox'>
                        <Checkbox checked={isSelected}
                            disabled={!kpi?.isPlottableToTrendChart}
                        />
                    </div>
                    <div className='data-label'>
                        <div className='health-icon'>
                            <HealthIcon kpi={kpi} />
                        </div>
                        <div className='condition-icon-label'>
                            {
                                userCapabilities?.hasOperationalParametersLimitStatus() &&
                                <div className='condition-icon'>
                                    {kpi?.limitStatus ?
                                        <IconCondition condition={limitIndications[kpi?.limitStatus]?.iconID} /> :
                                        null
                                    }
                                </div>
                            }
                            <div>{kpi.label}</div>
                        </div>
                        {kpi?.unit && <div className='data-label__unit'>{kpi?.unit}</div>}
                    </div>
                    {userCapabilities?.hasOperationalParametersMinMax() && <>
                        <div className='data-min-max'>
                            <span>{hasLengthSelectedTrendKpi ? minValue || minValue === 0 ? minValue + offset : '' : ''}</span>
                            <span>{hasLengthSelectedTrendKpi ? minValue !== null && maxValue !== null ? '/' : '-' : ''}</span>
                            <span>{hasLengthSelectedTrendKpi ? maxValue || maxValue === 0 ? maxValue + offset : '' : ''}</span>
                        </div>
                        <div className='data-average'>
                            <span>{hasLengthSelectedTrendKpi ? avgValue || avgValue === 0 ? avgValue + offset : '-' : ''}</span>
                        </div>
                    </>}
                    <div className='data-value'>{kpi.displayValue ?? '-'}</div>
                    <div className='data-command'>
                        {
                            map(KPI_BUTTONS, (KpiButton, index) => {
                                return <KpiButton
                                    kpi={kpi}
                                    capability={capability}
                                    isSelected={isSelected}
                                    dateSelection={dateSelection}
                                    key={index}
                                    setShowLimitSetting={setShowLimitSetting}
                                    setClickedConfigKpi={setClickedConfigKpi} />;
                            })
                        }

                    </div>
                </div>;
            })}
        </div>
    </Col>;
};

ParameterSection.propTypes = {
    section: PropTypes.shape({
        data: PropTypes.array,
        sectionId: PropTypes.any
    }),
    capability: PropTypes.object,
    selectedTrendKPIs: PropTypes.array
};

export default ParameterSection;
