import React from 'react';
import { map, sortBy } from 'lodash';
import { components } from 'react-select';
import { ASSET_TYPES_MS, allAssetTypes } from 'helpers/constants';
import { getAssetIcon } from 'helpers/icons';
import { Checkbox } from 'sharedComponents/Checkbox';
import colors from 'theme/_colors.scss';


export const getFormattedAssetTypes = (translate) => {
    const options = [];

    const types = Object.keys(ASSET_TYPES_MS);

    map(types, (type) => {
        const label = translate(`ABB.Powertrain.Frontend.${ASSET_TYPES_MS[type]?.toLowerCase()}Label`) ||
            ASSET_TYPES_MS[type];
        options.push({
            id: ASSET_TYPES_MS[type],
            title: <>{getAssetIcon(ASSET_TYPES_MS[type], { width: 16, height: 16 })} {label}</>,
            assetType: label
        });
    });

    return [
        {
            id: allAssetTypes,
            title: translate('ABB.Powertrain.Frontend.allassettypesLabel')
        },
        ...sortBy(options, [option => option?.assetType])
    ];
};

export const getFormattedAssetType = (type, translate) => {
    if (type) {
        const label = translate(`ABB.Powertrain.Frontend.${type?.toLowerCase()}Label`) || type;
        return {
            id: type,
            title: <>{getAssetIcon(type, { width: 16, height: 16 })} {label}</>,
        };
    }
};

export const customMultiSelectStyles = {
    control: (provided) => ({
        ...provided,
        width: 316,
        borderRadius: 0,
        minHeight: 32
    }),
    valueContainer: (provided) => ({
        ...provided,
       flexWrap: 'nowarp'
    }),
    multiValue: (provided) => ({
        ...provided,
        borderRadius: '50px'
    }),
    option: (base, state) => {
        let backgroundColor = colors.white;
        let color = colors.black;

        if (state?.isSelected) {
            backgroundColor = colors.white;
            color = colors.black;

        }
        if (state?.isFocused && !state?.isSelected) {
            backgroundColor = colors.altoTransparent;
            color = colors.black;
        }

        return {
            ...base,
            backgroundColor,
            color
        };
    }

};

export const getSubscriptionListOptions = (subscriptionTypes) => {
    return subscriptionTypes?.map((subscriptionType) => {
        return {
            label: subscriptionType?.name,
            value: subscriptionType?.name
        };
    });
};

export const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className='subscription-type'>
            <Checkbox
                checked={props?.isSelected}
                id={props?.label}
            />
            <label htmlFor={props?.label} className='subscription-type-label'>{props?.label}</label>
        </div>
      </components.Option>
    );
};

export const getSubsciptionTypeFilterValue = (subscriptionTypes, subscriptionTypeFilter) => {
    const getSubscriptionLists = getSubscriptionListOptions(subscriptionTypes);
    return getSubscriptionLists?.filter((subscriptionType) => subscriptionTypeFilter?.includes(subscriptionType.value));
};
