export const actionTypes = {
    GET_LIMIT_KPI: 'detailedInformation/limitConfiguration/GET_LIMIT_KPI',
    GET_LIMIT_KPI_SUCCESS: 'detailedInformation/limitConfiguration/GET_LIMIT_KPI_SUCCESS',
    GET_LIMIT_KPI_FAILURE: 'detailedInformation/limitConfiguration/GET_LIMIT_KPI_FAILURE',
    GET_TIME_SERIES: 'detailedInformation/limitConfiguration/GET_TIME_SERIES',
    GET_TIME_SERIES_SUCCESS: 'detailedInformation/limitConfiguration/GET_TIME_SERIES_SUCCESS',
    GET_TIME_SERIES_FAILURE: 'detailedInformation/limitConfiguration/GET_TIME_SERIES_FAILURE',
    ADD_SIGNAL: 'detailedInformation/limitConfiguration/ADD_SIGNAL',
    UPDATE_SIGNAL: 'detailedInformation/limitConfiguration/UPDATE_SIGNAL',
    LIMIT_TYPE_CHANGE: 'detailedInformation/limitConfiguration/LIMIT_TYPE_CHANGE',
    DELETE_LIMIT: 'detailedInformation/limitConfiguration/DELETE_LIMIT',
    UPDATE_LIMIT: 'detailedInformation/limitConfiguration/UPDATE_LIMIT',
    ADD_LIMIT: 'detailedInformation/limitConfiguration/ADD_LIMIT',
    DISCARD_LIMIT: 'detailedInformation/limitConfiguration/DISCARD_LIMIT',
    REMOVE_SIGNAL: 'detailedInformation/limitConfiguration/REMOVE_SIGNAL',
    RESET_STATE: 'detailedInformation/limitConfiguration/RESET_STATE',
    CANCEL: 'detailedInformation/limitConfiguration/CANCEL',
    POST_LIMIT_REQUEST: 'detailedInformation/limitConfiguration/POST_LIMIT_REQUEST',
    POST_LIMIT_SUCCESS: 'detailedInformation/limitConfiguration/POST_LIMIT_SUCCESS',
    POST_LIMIT_FAILURE: 'detailedInformation/limitConfiguration/POST_LIMIT_FAILURE',
    HANDLE_SIGNAL_UPDATE: 'detailedInformation/limitConfiguration/HANDLE_SIGNAL_UPDATE',
    HANDLE_SUGGEST_LIMIT_MODAL: 'detailedInformation/limitConfiguration/HANDLE_SUGGEST_LIMIT_MODAL',
    SELECTED_TIMESERIES_FOR_SUGGESTION: 'detailedInformation/limitConfiguration/SELECTED_TIMESERIES_FOR_SUGGESTION',
    GET_SUGGESTED_LIMIT_DATA: 'detailedInformation/limitConfiguration/GET_SUGGESTED_LIMIT_DATA',
    SET_SUGGESTED_LIMIT_DATA: 'detailedInformation/limitConfiguration/SET_SUGGESTED_LIMIT_DATA',
    UPDATE_SELECTED_SUGGESTED_SIGNAL: 'detailedInformation/limitConfiguration/UPDATE_SELECTED_SUGGESTED_SIGNAL',
    UPDATE_LIMIT_SELECTED_TIMESERIES: 'detailedInformation/limitConfiguration/UPDATE_LIMIT_SELECTED_TIMESERIES',
    RESET_SUGGESTED_CURRENT_LIMITS: 'detailedInformation/limitConfiguration/RESET_SUGGESTED_CURRENT_LIMITS',
    SET_EXPANDED_SIGNALS: 'detailedInformation/limitConfiguration/SET_EXPANDED_SIGNALS',
    GET_DEFAULT_LIMITS: 'detailedInformation/limitConfiguration/GET_DEFAULT_LIMITS',
    SET_DEFAULT_LIMIT_DATA: 'detailedInformation/limitConfiguration/SET_DEFAULT_LIMITS_DATA',
    UPDATE_DEFAULT_LIMITS: 'detailedInformation/limitConfiguration/UPDATE_DEFAULT_LIMITS',
};
