import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TimeSelectorRow, ToggleButton } from 'sharedComponents';
import { Graph } from '../ChartView/Graph';
import { getToggleOptions } from './helpers';
import { roleBasedCapabilityHelper } from 'helpers/capabilityHelper';
import { opButtons } from './constants';
import { useNewCapability } from 'sharedHooks';
import { check365Day, setLastWeekDates } from '../../helpers';

const ChartViewComponent = ({
    model: {
        activeTab,
        dateSelection
    },
    actions
}) => {
    const { t: translate } = useTranslation();
    const [toggleOptions] = useState(getToggleOptions());
    const newCapability = useNewCapability();
    const show365Days = activeTab !== opButtons.scatterPlot && activeTab !== opButtons.histogram;
    const hasTrendHistory365DaysAvailable = newCapability?.hasTrendHistory365DaysAvailable() && show365Days;

    const handleSelectDate = (from, to) => {
        if (from && to) {
            actions.setSelectedDates(from, to);
        }
    };

    const handleTabSelection = (tab) => {
        // Removed useCallback due to can't retrieve latest dataselection values
        actions.setActiveTab(tab);
        actions.resetChart();
        if (tab !== opButtons.trend && check365Day(dateSelection.from, dateSelection.to)) {
            setLastWeekDates(handleSelectDate);
        }
    };

    return (
        <Row className='cross-asset-analysis-content'>
            <Col md={12} className='cross-asset-analysis-tab-container'>
                <Row className='button-row-container'>
                    <Col xl={10} sm='auto' md={6}>
                        <ToggleButton
                            options={toggleOptions}
                            onSelected={handleTabSelection}
                            selectedValue={activeTab}
                        />
                    </Col>

                    <Col xl={2} sm='auto' md={6}>
                        <TimeSelectorRow
                            fromDate={dateSelection.from}
                            toDate={dateSelection.to}
                            label=''
                            handleSelectDate={handleSelectDate}
                            maxDaysRange={hasTrendHistory365DaysAvailable ? 365 : 90}
                            maxDaysBack={roleBasedCapabilityHelper.hasExtendedDateSelector() ? 365 : 90}
                            preSelectedDays={7}
                            numberOfMonths={2}
                            footerIntervalText={hasTrendHistory365DaysAvailable ? translate('ABB.Powertrain.Frontend.datePickerMax365DaysNote') : translate('ABB.Powertrain.Frontend.datePickerMax90DaysNote')}
                        />
                    </Col>
                </Row>
                <Graph />
            </Col>
        </Row>
    );
};

export default ChartViewComponent;
