import { map, sortBy, isArray, sumBy, findIndex } from 'lodash';
import { routes } from 'routes';
import { ASSET_TYPES_MS, filterTabs, NOTIFICATION_TYPES, allAssetTypes, apiCallStatus, NOTIFICATION_BAR } from 'helpers/constants';
import featureConfigHelper from 'helpers/featureConfigHelper';
import { pagesWithTrendChart, pageSize } from './constants';
import { getLegacyIdFromAsset, getAssetDataSourceType, getAssetTypeId } from 'helpers/assetAndPowertrainFilterHelper';
import { getMergedTrendKpiList } from './DetailedInformation/commonTrendHelpers';
import { convertUtcToLocalDateTime } from 'helpers/dateHelper';
import moment from 'moment';


export const getTabOptions = (translate) => {
    const featureConfig = new featureConfigHelper();

    return {
        detailedInformation: {
            title: translate('ABB.Powertrain.Frontend.ptAssetDetailsDetailedInformationTab'),
            route: routes.DetailedInformation,
            tabID: translate('ABB.Powertrain.Frontend.ptAssetDetailsDetailedInformationTabID'),
            hidden: true
        },
        crossKPI: {
            title: translate('ABB.Powertrain.Frontend.ptAssetDetailsCrossKPITab'),
            route: routes.CrossKpi,
            tabID: translate('ABB.Powertrain.Frontend.ptAssetDetailsCrossKPITabID'),
            hidden: true
        },
        conditionBasedMaintenance: {
            title: translate('ABB.Powertrain.Frontend.ptAssetDetailsCBMTab'),
            route: routes.ConditionBasedMaintenance,
            disabled: true,
            hidden: featureConfig.isCBMFeatureEnabled(),
            tabID: translate('ABB.Powertrain.Frontend.ptAssetDetailsCBMTabID')
        }
    };
};

export const createParamMeasurementAssetObj = (measurement, assetObj) => {
    const measurementArray = isArray(measurement) ? measurement : [measurement];
    return [{
        measurementTypes: map(measurementArray, (measurementObj) => {
            return {
                displayName: measurementObj.displayName,
                measurementTypeIdentifier: measurementObj.measurementTypeIdentifier,
                measurementTypeCode: measurementObj.measurementTypeCode,
                measurementTypeKey: measurementObj.measurementTypeKey,
                allSignal: measurementObj.allSignal
            };
        }),
        componentID: getLegacyIdFromAsset(assetObj),
        componentTypeID: getAssetTypeId(assetObj?.assetType),
        dataSourceType: getAssetDataSourceType(assetObj)
    }];
};

export const createParamMeasurementAssetObjTrend = (measurement, assetObj) => {
    const measurementArray = isArray(measurement) ? measurement : [measurement];
    return map(measurementArray, (kpi) => {
        return {
            assetId: assetObj?.id,
            timeseries: {
                timeseriesKey: kpi?.timeseriesKey
            }
        };
    });
};

export const afterChartReDraw = (chartContainerId, removeFn) => {
    const htmlElements = document.querySelectorAll(`#${chartContainerId} .remove-button`);
    map(htmlElements, element => {
        // Clone the node, in order to remove all previous event listeners
        const newElement = element.cloneNode(true);
        element.parentNode.replaceChild(newElement, element);
        newElement.addEventListener('click', (e) => {
            removeFn(e.currentTarget.id);
            const highChartLegends = document.querySelectorAll('div.highcharts-legend-item');
            const popoutChartElements = Array.from(highChartLegends).filter((ele) => ele.closest('.popout-chart-container'));
            // Get respective signal elements from the DOM
            const allButtons = popoutChartElements?.length ? popoutChartElements : highChartLegends;
            const hiddenButtons = Array.from(allButtons).filter(ele => ele.classList.contains('highcharts-legend-item-hidden'));
            if (allButtons && hiddenButtons) {
                const nonHiddenCount = allButtons.length - hiddenButtons.length;
                if (nonHiddenCount === 1 && hiddenButtons.length > 0) {
                    const nonHiddenEle = Array.from(allButtons).find(ele => !ele.classList.contains('highcharts-legend-item-hidden'));
                    if (nonHiddenEle.querySelector(`[id='${e.currentTarget.id}']`)) {
                        // Unhide the First signal when remove a single which is only unhided signal in the list.
                        hiddenButtons[0].querySelector('.item-kpi-name').click();
                    }
                }
            }
            e.stopPropagation();
        });
    });
};

export const afterChartReDrawStringData = (chartContainerId, removeFn) => {
    const htmlElements = document.querySelectorAll(`#${chartContainerId} .remove-button`);
    map(htmlElements, element => {
        // Clone the node, in order to remove all previous event listeners
        const newElement = element.cloneNode(true);
        element.parentNode.replaceChild(newElement, element);
        newElement.addEventListener('click', (e) => {
            removeFn(parseInt(e.currentTarget.id.split('_')[1]));
            const highChartLegends = document.querySelectorAll('div.highcharts-legend-item');
            const popoutChartElements = Array.from(highChartLegends).filter((ele) => ele.closest('.popout-chart-container'));
            // Get respective signal elements from the DOM
            const allButtons = popoutChartElements?.length ? popoutChartElements : highChartLegends;
            const hiddenButtons = Array.from(allButtons).filter(ele => ele.classList.contains('highcharts-legend-item-hidden'));
            if (allButtons && hiddenButtons) {
                const nonHiddenCount = allButtons.length - hiddenButtons.length;
                if (nonHiddenCount === 1 && hiddenButtons.length > 0) {
                    const nonHiddenEle = Array.from(allButtons).find(ele => !ele.classList.contains('highcharts-legend-item-hidden'));
                    if (nonHiddenEle.querySelector(`[id='${e.currentTarget.id}']`)) {
                        hiddenButtons[0].querySelector('.item-kpi-name').click();
                    }
                }
            }
            e.stopPropagation();
        });
    });
};

// Find signal index from selected KPI list for removing that signal.
export const getKPISignalIndexFromList = (selectedTrends, trendKPIsList, signalIndex) => {
    const getTrendKPI = getMergedTrendKpiList(trendKPIsList)[signalIndex];
    return findIndex(selectedTrends, (kpi) => typeof kpi === 'object' ? kpi.kpiIdentifier === getTrendKPI.timeseriesKey : kpi === getTrendKPI.timeseriesKey);
};

export const removeSelectedIndexData = (array, index) => {
    array.splice(index, 1);
    return array;
};

export const chartLegendItemClick = (e) => {
    if (e.target.visible) {
        const totalVisible = sumBy(
            e.target.chart.series,
            ({ visible, xData, index, legendItem }) => {
                return Number(visible === true
                    && (xData.length === 0 && e.target.index === index || xData.length > 0)
                    && !!legendItem);
            }
        );
        if (totalVisible === 1) {
            e.preventDefault();
            return false;
        }
    }
};

// export const getGatewayTypeOptions = (translate) => {
//     const gatewayTypeOptions = map(gatewayTypes, (gatewayType) => {
//         return {
//             id: gatewayType,
//             title: gatewayType === gatewayTypes.ALL ?
//                 translate(`ABB.Powertrain.Frontend.gatewayType${gatewayType}`)
//                 : gatewayTypeTitles[gatewayType]
//         };
//     });

//     return sortBy(gatewayTypeOptions, [entry => entry?.title?.toLowerCase()]);
// };

// export const getDefaultValue = (defaultValue, translate) => {
//     return {
//         id: defaultValue,
//         title: defaultValue === gatewayTypes.ALL ?
//             translate(`ABB.Powertrain.Frontend.gatewayType${defaultValue}`)
//             : gatewayTypeTitles[defaultValue]
//     };
// };

const getDriveContractInfo = (endDate, now, type) => {
    const link = {
        url: 'https://new.abb.com/contact-centers',
        description: 'ABB.Powertrain.Frontend.ContactABBDriveSupport'
    };
    const next60Days = new Date();
    next60Days.setDate(now?.getDate() + 60);
    const next30Days = new Date();
    next30Days.setDate(now?.getDate() + 30);
    if (!endDate && type === NOTIFICATION_BAR.FREE || type === NOTIFICATION_BAR.FREE_FOR_RENEWAL) {
        return {
            message: 'ABB.Powertrain.Frontend.NoContractForDrive',
            link,
            type: NOTIFICATION_TYPES.ERROR
        };
    }

    if (endDate) {
        endDate = new Date(endDate);
        const formattedEndDate = convertUtcToLocalDateTime(endDate, true, true);
        if (endDate < now) {
            return {
                message: 'ABB.Powertrain.Frontend.DriveContractExpired',
                messageParams: { endDate: formattedEndDate },
                link,
                type: NOTIFICATION_TYPES.ERROR
            };
        }
        endDate = new Date(endDate);
        if (endDate <= next30Days && endDate >= now) {
            return {
                message: 'ABB.Powertrain.Frontend.DriveContractExpiring',
                messageParams: { endDate: formattedEndDate },
                type: NOTIFICATION_TYPES.WARN
            };
        }
        if (endDate <= next60Days) {
            return {
                message: 'ABB.Powertrain.Frontend.DriveContractExpiring',
                messageParams: { endDate: formattedEndDate },
                type: NOTIFICATION_TYPES.WARN
            };
        }
    }
};

const getSmartSensorContractInfo = (isMonitored, endDate, now, type) => {
    const link = {
        url: 'https://new.abb.com/contact-centers',
        description: 'ABB.Powertrain.Frontend.ContactABBDriveSupport'
    };
    const next30Days = new Date();
    next30Days.setDate(now.getDate() + 30);
    if (!isMonitored) {
        return {
            message: 'ABB.Powertrain.Frontend.AssetIsUnmonitored',
            type: NOTIFICATION_TYPES.INFO
        };
    }
    if (!endDate && type === NOTIFICATION_BAR.FREE || type === NOTIFICATION_BAR.FREE_FOR_RENEWAL) {
        return {
            message: 'ABB.Powertrain.Frontend.NoSubscription',
            link,
            type: NOTIFICATION_TYPES.ERROR
        };
    }
    if (endDate) {
        endDate = new Date(endDate);
        if (endDate < now) {
            return {
                message: 'ABB.Powertrain.Frontend.NoSubscription',
                link,
                type: NOTIFICATION_TYPES.ERROR
            };
        }
        endDate = new Date(endDate);
        if (endDate <= next30Days && endDate >= now) {
            const formattedEndDate = convertUtcToLocalDateTime(endDate, true, true);
            return {
                message: 'ABB.Powertrain.Frontend.SubscriptionExpiring',
                messageParams: { endDate: formattedEndDate },
                type: NOTIFICATION_TYPES.WARN
            };
        }
    }
};


export const getContractInfo = (details, capability, loadingStatus) => {
    if (capability && loadingStatus === apiCallStatus.SUCCESS && details) {
        const endDate = capability[0]?.endDate;
        const now = new Date();
        const type = capability[0]?.type;

        if (details?.details?.assetType?.assetType === ASSET_TYPES_MS.DRIVE) {
            return getDriveContractInfo(endDate, now, type);
        } else {
            return getSmartSensorContractInfo(details?.isMonitored, endDate, now, type);
        }
    }
    return null;
};

export const toggleTabsWhenSelectionChanges = (query, activeTab, setActiveTabAction) => {
    if (query.powertrainId && activeTab !== filterTabs.POWERTRAINS) {
        setActiveTabAction(filterTabs.POWERTRAINS);
    }
    else if (!query.powertrainId && query.assetId && activeTab !== filterTabs.ASSETS) {
        setActiveTabAction(filterTabs.ASSETS);
    }
};

export const orderByAlphabet = (powertrains) => sortBy(powertrains, powertrain => powertrain?.systemName?.toLowerCase());

export const getZoomingDates = (state, page) => {
    let trendChartZoom = {};
    let anyKpiSelected = false;

    switch (page) {
        case pagesWithTrendChart.operationalParameters: {
            trendChartZoom = state?.operationalParameters?.charts?.single?.trend[0]?.zoom;
            anyKpiSelected = state?.operationalParameters?.charts?.single?.trend[0]?.kpiList?.length > 0;
            break;
        }
        case pagesWithTrendChart.operationalParametersV2: {
            trendChartZoom = state?.operationalParametersV2?.charts?.single?.trend[0]?.zoom;
            anyKpiSelected = state?.operationalParametersV2?.charts?.single?.trend[0]?.kpiList?.length > 0;
            break;
        }
        case pagesWithTrendChart.crossAssetVisualization: {
            trendChartZoom = state.chartView.trendChartZoom;
            anyKpiSelected = state.chartView.chartData?.length > 0;
            break;
        }
        default: {
            trendChartZoom = state.operationalParameters.trendChartZoom;
            anyKpiSelected = state.operationalParameters.trend.data.length > 0;
            break;
        }
    }

    const isZoomed = trendChartZoom.isZoomed;

    let { from, to } = state.overview.dateSelection;
    let zoomed = false;

    if (anyKpiSelected && isZoomed && trendChartZoom.from && trendChartZoom.to) {
        from = trendChartZoom.from;
        to = trendChartZoom.to;
        zoomed = trendChartZoom.isZoomed;
    }

    return { fromDate: from, toDate: to, zoomed };
};

export const formatConditionIndexOverviewRequest = (
    pageNumber,
    selectedFilters,
    filter,
    conditionFilter,
    assetTypeFilter = ''
) => {
    return {
        pageNumber,
        pageSize,
        organizationIds: selectedFilters?.organizations?.length && selectedFilters?.organizations?.map(organization => {
            return organization?.organizationID;
        }) || [],
        siteIds: selectedFilters?.sitesChecked && selectedFilters?.sites?.length &&
            selectedFilters?.sites.map(site => {
                return site?.siteID;
            }) || [],
        assetGroupIds: selectedFilters?.assetGroupChecked && selectedFilters?.assetGroups?.length &&
            selectedFilters?.assetGroups.map(assetGroup => {
                return assetGroup?.id;
            }) || [],
        filter,
        conditionFilter,
        assetTypeFilter: assetTypeFilter === allAssetTypes ? '' : assetTypeFilter,
        sortingCriteria: ['OverallCondition'],
        sortingOrder: 'asc'
    };
};

const routeNames = (translate) => {
    return {
        CONDITION_OVERVIEW: translate('ABB.Powertrain.Frontend.ptConditionMonitoringPowertrainsAssetsOverviewTab'),
        MAINTENANCE_OVERVIEW: translate('ABB.Powertrain.Frontend.ptMaintenanceOverviewTab'),
        SUBSCRIPTION_STATUS: translate('ABB.Powertrain.Frontend.ptToolsSubscriptionStatus')
    };
};

export const getRoutes = (routeName, translate) => {
    const routeDetails = routeNames(translate);
    let setRoutes;
    switch (routeName) {
        case routeDetails.CONDITION_OVERVIEW: {
            setRoutes = {
                routePath: routes?.ConditionOverview,
                routeName: routeDetails.CONDITION_OVERVIEW
            };
            break;
        }
        case routeDetails.MAINTENANCE_OVERVIEW: {
            setRoutes = {
                routePath: routes?.MaintenanceOverview,
                routeName: routeDetails.MAINTENANCE_OVERVIEW
            };
            break;
        }
        case routeDetails.SUBSCRIPTION_STATUS: {
            setRoutes = {
                routePath: routes?.ToolsSubscriptionStatus,
                routeName: routeDetails.SUBSCRIPTION_STATUS
            };
            break;
        }
        default: {
            setRoutes = {
                routePath: routes?.ConditionOverview,
                routeName: routeDetails.CONDITION_OVERVIEW
            };
        }
    }
    return setRoutes;
};

export const check365Day = (from, to) => {
    if (from && to) {
        return moment(to).diff(moment(from), 'days') > 90;
    } else {
        return true;
    }
};

export const setLastWeekDates = (setSelectedDates) => {
    // Set current day time to 23:59:59 for configure 7 days diffrence.
    const currentDay = moment().endOf('day').parseZone().utc().format();
    // Set last week day with start day time to 00:00:00 for round of 7 days.
    const lastWeekDay = moment().subtract(6, 'd').startOf('day').parseZone().utc().format();
    setSelectedDates(lastWeekDay, currentDay);
};
