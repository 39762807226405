export const actionTypes = {
    GET_ASSET_KPI_TREND_LIST: 'assetConditionInsights/GET_ASSET_KPI_TREND_LIST',
    LOAD_ASSET_KPI_TREND_LIST: 'assetConditionInsights/LOAD_ASSET_KPI_TREND_LIST',
    ADD_SELECTED_TREND_KPI_DATA: 'assetConditionInsights/ADD_SELECTED_TREND_KPI_DATA',
    ZOOM_IN_TO_SELECTED_TREND_KPI_DATA: 'assetConditionInsights/ZOOM_IN_TO_SELECTED_TREND_KPI_DATA',
    RELOAD_CONDITION_INSIGHTS: 'assetConditionInsights/RELOAD_CONDITION_INSIGHTS',
    SET_CONDITION_INSIGHTS_ZOOM: 'assetConditionInsights/SET_CONDITION_INSIGHTS_ZOOM',
    RELOAD_CONDITION_INSIGHTS_SUCCESS: 'assetConditionInsights/RELOAD_CONDITION_INSIGHTS_SUCCESS',
    SET_CONDITION_INSIGHTS_ZOOM_SUCCESS: 'assetConditionInsights/SET_CONDITION_INSIGHTS_ZOOM_SUCCESS'
};

export const DRIVE_STATUS_KEY = 'DriveStatus';

export const OVER_ALL_STATUS = {
    Poor: 3,
    Tolerable: 2,
    Good: 1,
    Unknown: 0,
};

export const KPI_TYPE = {
    Threshold: 'Threshold',
    LongTerm: 'LongTerm',
    ShortTerm: 'ShortTerm',
};
