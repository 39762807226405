import { CHART_PLUGINS } from 'sharedComponents/Chart/constants';
import colors from 'theme/_colors.scss';

export const chartPlugins = [CHART_PLUGINS.NO_DATA_TO_DISPLAY, CHART_PLUGINS.HIGHCHARTS_MORE, CHART_PLUGINS.BOOST, CHART_PLUGINS.EXPORTING, CHART_PLUGINS.OFFLINE_EXPORTING];

export const CHART_PLOT_LINE_DASH_STYLE = 'shortdash';
export const CHART_CONDITION_TREND_STATUS_POOR = 'Poor';
export const CHART_CONDITION_TREND_STATUS_GOOD = 'Good';

export const CHART_SERIES_CONDITION_INDICATOR_NAME = 'conditionindicator';
export const ALERT_LINE_COLOR = '#FFA200';
export const MARKER_FILL_COLOR = '#ffffff';

export const ALARM_LINE_COLOR = '#EF3A34';
export const TREND_LINE_COLOR = '#492970';

export const CHART_TREND_EVENT_TOLERABLE_INDICATOR =
    '<svg width="16" height="32" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="16" height="16" fill="#FFA200"/><path d="M11.3235 11.4527L13.085 4.87868L6.51096 3.11719L6.03056 4.9101L9.61637 5.87091L2.03865 10.2459L2.91365 11.7615L10.4914 7.38646L9.53056 10.9723L11.3235 11.4527Z" fill="white"/><line x1="8" y1="16" x2="8" y2="31" stroke="#FFA200"/><circle cx="8" cy="30" r="2" fill="#FFA200"/></svg>';
export const CHART_TREND_EVENT_POOR_INDICATOR =
    '<svg width="16" height="32" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="16" height="16" fill="#EF3A34"/><path d="M12.7652 9.05056L11.0037 2.47656L4.42969 4.23806L4.9101 6.03097L8.49591 5.07015L4.12091 12.6479L5.63646 13.5229L10.0115 5.94515L10.9723 9.53097L12.7652 9.05056Z" fill="white"/><line x1="8" y1="16" x2="8" y2="31" stroke="#EF3A34"/><circle cx="8" cy="30" r="2" fill="#EF3A34"/></svg>';

export const CHART_TREND_EVENT_TOLERABLE_INDICATOR_TOOLTIP = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><rect width="16" height="16" fill="#FFA200"/><path d="M11.3235 11.4527L13.085 4.87868L6.51096 3.11719L6.03056 4.9101L9.61637 5.87091L2.03865 10.2459L2.91365 11.7615L10.4914 7.38646L9.53056 10.9723L11.3235 11.4527Z" fill="white"/></svg>';
export const CHART_TREND_EVENT_POOR_INDICATOR_TOOLTIP = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><rect y="0.5" width="16" height="16" fill="#EF3A34"/><path d="M12.7652 9.55056L11.0037 2.97656L4.42969 4.73806L4.9101 6.53097L8.49591 5.57015L4.12091 13.1479L5.63646 14.0229L10.0115 6.44515L10.9723 10.031L12.7652 9.55056Z" fill="white"/></svg>';
export const TREND_TYPE_TOOLTIP_SERIES_NAME = 'kpi-trend-type-tooltip';
export const CONDITION_STATUS_LABEL = {
    KpiShortTerm: 'conditionTrendTypeShortTermLabel',
    KpiLongTerm: 'conditionTrendTypeLongTermLabel',
    KpiThreshold: 'conditionTrendTypeThresholdLabel',
};
export const CHART_CONDITION_TREND_KPI_TYPE = 'Kpi';
export const statusBarColors = {
    Poor: colors.pomegranate,
    Tolerable: colors.orangePeel,
    Good: colors.jungleGreen,
    Unknown: colors.smoke
};
