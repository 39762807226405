import PropTypes from 'prop-types';
import React from 'react';
import { InfoMessage, PopOutChart } from 'sharedComponents';
import { chartPlugins } from './constants';
import useChartConfig from './useChartConfig';
/*import {
    EnableUniqueScaling,
    LastSyncDate,
    PopOutButton,
    ResetZoomButton,
    withLeftButtonGroup,
    withPopOutRow,
    withRightButtonGroup
} from 'sharedComponents/Chart/headers';*/


const TrendChart = ({
    selectedTrendKPIs,
    selectedTrendsData,
    removeSelectedKpiLegend,
    trendDataLoadingStatus,
    userLimits,
    enableUniqueScaling,
    lastMeasurementTimeStampLabel,
    isZoomed,
    chartKey = '',
    reloadAllSelectedTrendKpiData,
    setIsZoomed,
    zoomInToSelectedTrendKpiData,
    yAxisTitleVisible,
    mainChartHeader,
    warningLevels,
    warningLines,
    conditionTrendIndicators,
    highErrorValue,
    highAlertLimitValue,
    lowErrorValue,
    lowAlertValue,
    unitGroupId,
    title,
    assetId,
    assetDetails,
    valueType,
    conditionTrendsData

}) => {
    const resetServerZoom = () => {
        reloadAllSelectedTrendKpiData(assetId);
    };

    const zoomIn = (from, to) => {
        zoomInToSelectedTrendKpiData(assetId, from, to);
    };
    const graphOptions = useChartConfig(
        selectedTrendsData,
        userLimits,
        yAxisTitleVisible,
        removeSelectedKpiLegend,
        warningLevels,
        warningLines,
        conditionTrendIndicators,
        highErrorValue,
        highAlertLimitValue,
        lowErrorValue,
        lowAlertValue,
        unitGroupId,
        title,
        assetDetails,
        valueType,
        conditionTrendsData
    );

    /*const CustomHeader = withPopOutRow([
        withLeftButtonGroup([LastSyncDate, EnableUniqueScaling]),
        withRightButtonGroup([ResetZoomButton, PopOutButton])]);*/

    return (
        <PopOutChart
            hasKpi={!!selectedTrendKPIs?.length}
            renderNoKpi={() => <InfoMessage
                title='ABB.Powertrain.Frontend.chartNoKPIsSelected'
                text='ABB.Powertrain.Frontend.chartNoKPIsSelectedUserGuide' />}
            hasData={!!selectedTrendsData?.length}
            renderNoData={() => <InfoMessage
                title='ABB.Powertrain.Frontend.chartNoMeasurementData'
                text='ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide' />}
            enableUniqueScaling={enableUniqueScaling}
            lastMeasurementLabel={lastMeasurementTimeStampLabel}
            zoomInServer={zoomIn}
            resetServerZoom={resetServerZoom}
            isZoomed={isZoomed}
            setIsZoomed={setIsZoomed}
            zoomInToSelectedTrendKpiData={zoomInToSelectedTrendKpiData}
            loadingStatus={trendDataLoadingStatus}
            options={graphOptions}
            modules={chartPlugins}
            container={chartKey}
            mainChartHeader={mainChartHeader}
        />
    );
};

TrendChart.propTypes = {
    reloadAllSelectedTrendKpiData: PropTypes.func,
    setIsZoomed: PropTypes.func,
    zoomInToSelectedTrendKpiData: PropTypes.func,
    selectedTrendKPIs: PropTypes.array,
    enableUniqueScaling: PropTypes.bool,
    lastMeasurementTimeStampLabel: PropTypes.string,
    selectedTrendsData: PropTypes.array,
    availableTrendKPIs: PropTypes.array,
    trendDataLoadingStatus: PropTypes.string,
    userLimits: PropTypes.array,
    isZoomed: PropTypes.bool,
    chartKey: PropTypes.string,
    yAxisTitleVisible: PropTypes.bool,
    legendVisible: PropTypes.bool,
    warningLevels: PropTypes.array,
    warningLines: PropTypes.array,
    conditionTrendIndicators: PropTypes.array,
    highErrorValue: PropTypes.number,
    highAlertLimitValue: PropTypes.number,
    lowErrorValue: PropTypes.number,
    lowAlertValue: PropTypes.number,
    unitGroupId: PropTypes.number,
    title: PropTypes.string,
    assetDetails: PropTypes.object,
    valueType: PropTypes.string,
};

export default TrendChart;
