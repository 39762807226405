import { find, map } from 'lodash';

import { requestStatuses, responseStatuses } from '../../constants';
import { translate } from 'helpers/translateHelper';

export const getSensorFeature = (sensorFeatures, sensorFeature) => {
    return find(sensorFeatures, (s) => {
        return s.featureKeyName === sensorFeature;
    });
};

export const getRequestStatusText = (featureValue) => {
    switch (featureValue) {
        case requestStatuses.COMPLETED:
            return translate('ABB.Powertrain.Frontend.requestStatusCompletedText');
        case requestStatuses.ERROR:
            return translate('ABB.Powertrain.Frontend.requestStatusErrorText');
        case requestStatuses.PENDING:
            return translate('ABB.Powertrain.Frontend.requestStatusPendingText');
        case requestStatuses.NOT_REQUESTED_INITIAL:
        case requestStatuses.NOT_REQUESTED:
        default:
            return translate('ABB.Powertrain.Frontend.requestStatusNotRequestedText');
    }
};

export const getResponseStatusText = (featureValue) => {
    switch (featureValue) {
        case responseStatuses.COMPLETED_SUCCESSFULLY:
            return translate('ABB.Powertrain.Frontend.responseStatusCompletedSuccessfullyText');
        case responseStatuses.COULD_NOT_CONNECT_TO_THE_SENSOR:
            return translate('ABB.Powertrain.Frontend.responseStatusCouldNotConnectText');
        case responseStatuses.INTERNAL_ERROR:
            return translate('ABB.Powertrain.Frontend.responseStatusInternalErrorText');
        case responseStatuses.NO_MEASUREMENT_AVAILABLE:
            return translate('ABB.Powertrain.Frontend.responseStatusNoMeasurementAvailableText');
        case responseStatuses.SENSOR_NOT_FOUND:
            return translate('ABB.Powertrain.Frontend.responseStatusSensorNotFoundText');
        case responseStatuses.WRONG_PASSKEY:
            return translate('ABB.Powertrain.Frontend.responseStatusWrongPasskeyText');
        case responseStatuses.INVALID_PARAMETERS:
            return translate('ABB.Powertrain.Frontend.responseStatusInvalidRequestParametersText');
        case responseStatuses.DEGRADED_MODE:
            return translate('ABB.Powertrain.Frontend.responseStatusDegradedModeText');
        default: return null;
    }
};

export const isRequestStatusRequested = requestStatus => {
    return requestStatus !== requestStatuses.NOT_REQUESTED && requestStatus !== requestStatuses.NOT_REQUESTED_INITIAL;
};

export const formatMultiToggleOptions = (options) => {
    return map(options, (option) => {
        return {
            ...option,
            label: translate(option.label)
        };
    });
};
