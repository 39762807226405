
import {
    indexOf,
    without,
    concat,
    fromPairs,
    map,
    filter,
    transform,
    find
} from 'lodash';
//Check if lodash import can be made smaller
import { allAssetTypes } from 'helpers/constants';
import { translate } from 'helpers/translateHelper';

export const selectState = (selectedStates = [], newState) => {
    return indexOf(selectedStates, newState) > -1 ? without(selectedStates, newState) : concat(selectedStates, newState);
};

const matchesSiteAndCountry = (item, filterObject) => {
    const countryMatches = filterObject.countryCodes ? filterObject.countryCodes[item.site?.countryCode] : true;
    const siteMatches = filterObject.siteNames ? filterObject.siteNames[item.site?.siteName] : true;

    return countryMatches && siteMatches;
};

const matchesSiteAndCountryV2 = (item, filterObject) => {
    const countryMatches = filterObject.countryCodes ? filterObject.countryCodes[item.site?.countryCode] : true;
    const siteMatches = filterObject.siteNames ? filterObject.siteNames[item.site?.name] : true;

    return countryMatches && siteMatches;
};

const matchesAssetGroup = (asset, filterObject) => {
    const selectedAssetGroupId = new Set(filterObject?.assetGroups?.map(assetGroup => assetGroup?.id));
    return selectedAssetGroupId.size ?
        !!find(asset.assetGroupIds, (assetGroupId) => selectedAssetGroupId.has(assetGroupId)) :
        true;
};

const matchesPowertrainAssetGroup = (powertrain, filterObject) => {
    const filteredPowertrainAssetGroup = powertrain?.assets?.length && powertrain?.assets.filter((asset) => matchesAssetGroup(asset, filterObject));
    return filteredPowertrainAssetGroup.length;
};

export const generalFilterAssets = (assets, {
    sitesChecked,
    assetGroupChecked,
    countries = [],
    sites = [],
    assetGroups
}) => {
    const filterObject = {
        countryCodes: sitesChecked && countries.length ? fromPairs(map(countries, (country) => [country.countryCode, true])) : null,
        siteNames: sitesChecked && sites.length ? fromPairs(map(sites, (site) => [site.siteName, true])) : null,
        assetGroups: assetGroupChecked && assetGroups.length ? assetGroups : null,
    };

    return filter(assets, (asset) => {
        return matchesSiteAndCountry(asset, filterObject) && matchesAssetGroup(asset, filterObject);
    }) || [];
};

export const generalFilterAssetsV2 = (assets, {
    sitesChecked,
    assetGroupChecked,
    countries = [],
    sites = [],
    assetGroups
}) => {
    const filterObject = {
        countryCodes: sitesChecked && countries.length ? fromPairs(map(countries, (country) => [country.countryCode, true])) : null,
        siteNames: sitesChecked && sites.length ? fromPairs(map(sites, (site) => [site.siteName, true])) : null,
        assetGroups: assetGroupChecked && assetGroups.length ? assetGroups : null,
    };

    return filter(assets, (asset) => {
        return matchesSiteAndCountryV2(asset, filterObject) && matchesAssetGroup(asset, filterObject);
    }) || [];
};

// eslint-disable-next-line import/no-unused-modules
export const generalFilterAssetsWithMicroId = (assets, allAssetsFromMicro) => {
    return assets?.map(asset => {
        const microAsset = allAssetsFromMicro?.find(microAsset => microAsset?.legacyAssetId?.toString() === asset?.componentID?.toString());
        return {
            ...asset,
            id: microAsset?.id
        };
    });
};

export const generalFilterPowertrains = (powertrains, {
    sitesChecked,
    countries,
    sites,
    assetGroups,
    assetGroupChecked
}) => {

    const filterObject = {
        countryCodes: sitesChecked && countries.length ? fromPairs(map(countries, (country) => [country.countryCode, true])) : null,
        siteNames: sitesChecked && sites.length ? fromPairs(map(sites, (site) => [site.siteName, true])) : null,
        assetGroups: assetGroupChecked ? assetGroups : null,
    };

    return filter(powertrains, (powertrain) => {
        const filteredAssets = powertrain?.assets?.length && filter(powertrain.assets, (asset) => matchesAssetGroup(asset, filterObject));
        if (filteredAssets.length > 0) {
            powertrain.assets = filteredAssets;
            return matchesSiteAndCountry(powertrain, filterObject) && matchesPowertrainAssetGroup(powertrain, filterObject);
        }
        return false;
    }) || [];
};

/**
 * Currying function which returns a function that sorts input according to received "key"
 * @param key string key of the sortable property
 * @param order string : "asc" | "desc"
 *
 * @returns Sorting function to be used in e.g. .sort( ) calls.
 */

export const sortByKey = (key, order = 'asc') => (a, b) => {
    if (order === 'desc') {
        return b[key]?.toLowerCase().localeCompare(a[key]?.toLowerCase(), undefined, { numeric: true });
    }
    return a[key]?.toLowerCase().localeCompare(b[key]?.toLowerCase(), undefined, { numeric: true });
};

export const mapUnknownCountry = (mappedCountries) => {
    const allCountries = transform(mappedCountries, (acc, c) => {
        const ret = {
            ...c
        };
        if (c.countryCode) {
            ret.countryDisplayName = translate(`ABB.Powertrain.Frontend.countryLabel_${c.countryCode}`) || c.countryName;
            acc.countries.push(ret);
        }
        else {
            ret.countryDisplayName = translate('ABB.Powertrain.Frontend.unknownCountry');
            acc.unknownCountry = ret;
        }
    }, { countries: [], unknownCountry: null });

    const sortedCountries = allCountries.countries.sort((a, b) =>
        (a.countryDisplayName || '').toLowerCase().localeCompare(b.countryDisplayName.toLowerCase())
    );

    if (allCountries.unknownCountry) {
        sortedCountries.push(allCountries.unknownCountry);
    }

    return sortedCountries;
};

export const allAssetTypeFilter = (translate) => {
    return {
        id: 0,
        title: translate('ABB.Powertrain.Frontend.allassettypesLabel'),
        assetType: allAssetTypes
    };
};
