import PropTypes from 'prop-types';
import React from 'react';
import { TrendChart } from '../../../CommonCharts/Trend';
import {
    EnableUniqueScaling,
    //LastSyncDate,
    PopOutButton,
    ResetZoomButton,
    withLeftButtonGroup,
    withPopOutRow,
    withRightButtonGroup
} from 'sharedComponents/Chart/headers';
import IconCondition from 'svgIcons/MotionPortalIcons/IconCondition';
import './style.scss';


const TrendChartComponent = ({
    model: {
        selectedTrendKPIs,
        userLimits,
        enableUniqueScaling,
        lastMeasurementTimeStampLabel,
        availableTrendKPIs,
        selectedTrendsData: {
            data,
            loadingStatus,
            zoom: { isZoomed },
            chartID
        },
        assetOverAllStatus,
        title,
        warningLevels,
        warningLines,
        conditionTrendIndicators,
        highErrorValue,
        highAlertLimitValue,
        lowErrorValue,
        lowAlertValue,
        unitGroupId,
        valueType,
        conditionTrendsData
    },
    assetId,
    actions: {
        reloadAllSelectedTrendKpiData,
        setIsZoomed,
        zoomInToSelectedTrendKpiData,
        removeSelectedKpiLegend
    },
    assetDetails
}) => {

    const zoomInServer = (assetId, from, to) => {
        zoomInToSelectedTrendKpiData(assetId, from, to);
    };

    const resetServerZoom = (assetId) => {
        reloadAllSelectedTrendKpiData(assetId);
    };

    const AssetStatusIndicator = () => {
        return <div className='kpi_status_title'>
            <span><IconCondition condition={assetOverAllStatus}></IconCondition></span>
            <b>{title}</b>
        </div>;
    };

    const CustomHeader = withPopOutRow([
        withLeftButtonGroup([AssetStatusIndicator, EnableUniqueScaling]),
        withRightButtonGroup([ResetZoomButton, PopOutButton])]);
    return (
        <TrendChart
            selectedTrendKPIs={selectedTrendKPIs}
            selectedTrendsData={data}
            removeSelectedKpiLegend={removeSelectedKpiLegend}
            trendDataLoadingStatus={loadingStatus}
            userLimits={userLimits}
            enableUniqueScaling={enableUniqueScaling}
            lastMeasurementTimeStampLabel={lastMeasurementTimeStampLabel}
            LastSyncDate={false}
            isZoomed={isZoomed}
            availableTrendKPIs={availableTrendKPIs}
            chartKey={chartID}
            reloadAllSelectedTrendKpiData={resetServerZoom}
            zoomInToSelectedTrendKpiData={zoomInServer}
            setIsZoomed={setIsZoomed}
            mainChartHeader={CustomHeader}
            yAxisTitleVisible={false}
            warningLevels={warningLevels}
            warningLines={warningLines}
            conditionTrendIndicators={conditionTrendIndicators}
            highErrorValue={highErrorValue}
            highAlertLimitValue={highAlertLimitValue}
            lowErrorValue={lowErrorValue}
            lowAlertValue={lowAlertValue}
            unitGroupId={unitGroupId}
            title={title}
            assetId={assetId}
            assetDetails={assetDetails}
            valueType={valueType}
            conditionTrendsData={conditionTrendsData}
        />
    );
};

TrendChartComponent.propTypes = {
    actions: PropTypes.shape({
        reloadAllSelectedTrendKpiData: PropTypes.func,
        setIsZoomed: PropTypes.func,
        zoomInToSelectedTrendKpiData: PropTypes.func
    }),
    model: PropTypes.shape({
        selectedTrendKPIs: PropTypes.array,
        enableUniqueScaling: PropTypes.bool,
        lastMeasurementTimeStampLabel: PropTypes.string,
        selectedTrendsData: PropTypes.shape({
            loadingStatus: PropTypes.string,
            data: PropTypes.array,
            zoom: PropTypes.shape({
                isZoomed: PropTypes.bool,
            }),
            chartID: PropTypes.string
        }),
        userLimits: PropTypes.array,
        assetOverAllStatus: PropTypes.number,
        title: PropTypes.string,
        warningLevels: PropTypes.array,
        warningLines: PropTypes.array,
        conditionTrendIndicators: PropTypes.array,
        highErrorValue: PropTypes.number,
        highAlertLimitValue: PropTypes.number,
        lowErrorValue: PropTypes.number,
        lowAlertValue: PropTypes.number,
        unitGroupId: PropTypes.number,
        valueType: PropTypes.string

    }),
    assetDetails: PropTypes.object
};

export default TrendChartComponent;
