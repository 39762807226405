import { map, sumBy, filter } from 'lodash';
import { DRIVE_STATUS_KEY, KPI_TYPE, OVER_ALL_STATUS } from './constants';
// eslint-disable-next-line import/no-unused-modules
export const afterChartReDrawStringData = (chartContainerId, removeFn) => {
    const htmlElements = document.querySelectorAll(`#${chartContainerId} .remove-button`);
    map(htmlElements, element => {
        // Clone the node, in order to remove all previous event listeners
        const newElement = element.cloneNode(true);
        element.parentNode.replaceChild(newElement, element);
        newElement.addEventListener('click', (e) => {
            removeFn(parseInt(e.currentTarget.id.split('_')[1]));
            const highChartLegends = document.querySelectorAll('div.highcharts-legend-item');
            const popoutChartElements = Array.from(highChartLegends).filter((ele) => ele.closest('.popout-chart-container'));
            // Get respective signal elements from the DOM
            const allButtons = popoutChartElements?.length ? popoutChartElements : highChartLegends;
            const hiddenButtons = Array.from(allButtons).filter(ele => ele.classList.contains('highcharts-legend-item-hidden'));
            if (allButtons && hiddenButtons) {
                const nonHiddenCount = allButtons.length - hiddenButtons.length;
                if (nonHiddenCount === 1 && hiddenButtons.length > 0) {
                    const nonHiddenEle = Array.from(allButtons).find(ele => !ele.classList.contains('highcharts-legend-item-hidden'));
                    if (nonHiddenEle.querySelector(`#${e.currentTarget.id}`)) {
                        hiddenButtons[0].querySelector('.item-kpi-name').click();
                    }
                }
            }
            e.stopPropagation();
        });
    });
};
// eslint-disable-next-line import/no-unused-modules
export const chartLegendItemClick = (e) => {
    if (e.target.visible) {
        const totalVisible = sumBy(
            e.target.chart.series,
            ({ visible, xData, index, legendItem }) => {
                return Number(visible === true
                    && (xData.length === 0 && e.target.index === index || xData.length > 0)
                    && !!legendItem);
            }
        );
        if (totalVisible === 1) {
            e.preventDefault();
            return false;
        }
    }
};

export const getDriveStatusData = (driveChartData) => {
    return driveChartData?.conditionTrends && driveChartData?.conditionTrends?.length > 0 ? driveChartData?.conditionTrends[0]?.values : [];

};

export const getDriveOverAllStatus = (driveChartData) => {
    return driveChartData?.currentCondition ? OVER_ALL_STATUS[driveChartData?.currentCondition] : 0;
};

export const getAllKPITrendChartList = (KPIList, data) => {
    if (!KPIList) {
        KPIList = data;
    }
    return KPIList?.filter((trendKPIItem) => trendKPIItem?.key !== DRIVE_STATUS_KEY);
};

export const getDriveKPIList = (KPIList) => {
    const driveList = KPIList?.filter((trendKPIItem) => trendKPIItem?.key === DRIVE_STATUS_KEY);
    return driveList && driveList[0] ? driveList[0] : 0;
};

export const getDateRange = () => {
    const fromDate = new Date();
    fromDate.setDate(fromDate?.getDate() - 89);
    const toDate = new Date();
    return {
        from: fromDate?.toISOString(),
        to: toDate?.toISOString(),
    };
};

export const getConditionStatus = (status) => {
    return OVER_ALL_STATUS[status];
};

//Combine Threhold, Long term and short term chart to a single chart
export const combinedTrendCharts = (trendKPI) => {
    const combinedTrendData = [];
    map(trendKPI, kpi => {
        let conditionEvents = kpi.conditionEvents;
        if (kpi.key.includes(KPI_TYPE.Threshold)) {
            const getLongAndShortTerm = filter(trendKPI, getKPI => getKPI.name.includes(kpi.name) && (
                getKPI.key.includes(KPI_TYPE.ShortTerm) || getKPI.key.includes(KPI_TYPE.LongTerm)
            ));
            if (getLongAndShortTerm?.length) {
                // Assign short and long term condition events data to threshold chart
                conditionEvents = map(getLongAndShortTerm, filteredKPI => filteredKPI.conditionEvents)?.flat();
            }
            combinedTrendData.push({
                ...kpi,
                conditionEvents,
            });
        }
    });
    return combinedTrendData;
};
