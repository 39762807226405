import { isEqual, orderBy } from 'lodash';
import { actionTypes } from './constants';
import { updateLimitReducerData, updateSuggestedLimitReducerData, updateSelectedTimeSeriesInListReducerData, mergeSuggestedLimits, handleUnitConversionSuggestedLimits, handleDiscardLimits, updateDefaultLimitsToCurrentLimits } from './helper';
import { apiCallStatus } from 'helpers/constants';

const initialState = {
    limitKPI: {
        kpiList: [],
        error: null,
        isLoading: null
    },
    currentTimeSeries: [],
    timeSeries: {
        timeSeriesList: [],
        error: null,
        isLoading: null,
        assetId: null
    },
    showSuggestLimitModal: false,
    selectedTimeSeriesForSuggestion: null,
    suggestedLimit: {
        data: null,
        isLoading: false
    },
    expandedSignals: [],
    defaultLimit: {
        data: null,
        isLoading: false
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TIME_SERIES:
            return {
                ...state,
                timeSeries: {
                    ...state.timeSeries,
                    isLoading: apiCallStatus.LOADING,
                },
            };
        case actionTypes.GET_TIME_SERIES_SUCCESS: {
            const { temp, assetId } = action.payload;
            return {
                ...state,
                timeSeries: {
                    timeSeriesList: [...temp],
                    isLoading: apiCallStatus.SUCCESS,
                    error: null,
                    assetId: assetId,
                },
                currentTimeSeries: [...temp]
            };
        }
        case actionTypes.GET_TIME_SERIES_FAILURE:
            return {
                ...state,
                timeSeries: {
                    timeSeriesList: [],
                    isLoading: apiCallStatus.SUCCESS,
                    error: action.payload
                },
            };
        case actionTypes.GET_LIMIT_KPI_SUCCESS:
            return {
                ...state,
                limitKPI: {
                    kpiList: action.payload,
                    error: null,
                    isLoading: 'success'
                }
            };
        case actionTypes.ADD_SIGNAL: {
            const signalExists = state.currentTimeSeries.some(
                item => item.displayName === action.payload.displayName
            );

            //to show error if signal is not selected and trying to add more signals
            if (signalExists) {
                return {
                    ...state,
                    currentTimeSeries: state.currentTimeSeries.map(item => !item.displayName
                        ? { ...item, isSignalError: true }
                        : item
                    )
                };
            }

            return {
                ...state,
                currentTimeSeries: [action.payload, ...state.currentTimeSeries]
            };
        }
        case actionTypes.UPDATE_SIGNAL: {
            const { uniqId, field, value } = action.payload;
            return {
                ...state,
                currentTimeSeries: state.currentTimeSeries?.map(item =>
                    item.uniqId === uniqId ? { ...item, [field]: value } : item
                )
            };
        }
        case actionTypes.LIMIT_TYPE_CHANGE: {
            const { updatedItem } = action.payload;
            const updatedTimeSeries = state.currentTimeSeries.map(item =>
                item.uniqId === updatedItem.uniqId ? updatedItem : item
            );

            return {
                ...state,
                currentTimeSeries: updatedTimeSeries,
            };
        }
        case actionTypes.DELETE_LIMIT: {
            const { item } = action.payload;

            // Find the currentTimeSeries item to update
            const currentTimeSeriesItemIndex = state.currentTimeSeries.findIndex(currentItem =>
                currentItem.limits.some(limit => limit.uniqId === item.uniqId)
            );

            const updatedCurrentTimeSeries = {
                ...state.currentTimeSeries[currentTimeSeriesItemIndex],
                limits: state.currentTimeSeries[currentTimeSeriesItemIndex].limits.filter(
                    limit => limit.uniqId !== item.uniqId
                )
            };

            // Find the corresponding item in timeSeriesList for comparison
            const correspondingTimeSeriesItem = state.timeSeries.timeSeriesList.find(item =>
                item.uniqId === state.currentTimeSeries[currentTimeSeriesItemIndex].uniqId
            );

            if (correspondingTimeSeriesItem !== undefined) {
                // Update the currentTimeSeries item by removing the limit

                const isModified = !isEqual(updatedCurrentTimeSeries.limits, correspondingTimeSeriesItem.limits);

                // Update isModified in updatedCurrentTimeSeries
                updatedCurrentTimeSeries.isModified = isModified;


            } else {
                updatedCurrentTimeSeries.isModified = true;
            }

            // Update state with updated currentTimeSeries item
            return {
                ...state,
                currentTimeSeries: [
                    ...state.currentTimeSeries.slice(0, currentTimeSeriesItemIndex),
                    updatedCurrentTimeSeries,
                    ...state.currentTimeSeries.slice(currentTimeSeriesItemIndex + 1)
                ]
            };
        }

        case actionTypes.UPDATE_LIMIT: {
            const { updatedTimeSeries, currentTimeSeriesItemIndex } = updateLimitReducerData(state, action);
            // Update state with updated currentTimeSeries item
            return {
                ...state,
                currentTimeSeries: [
                    ...state.currentTimeSeries.slice(0, currentTimeSeriesItemIndex),
                    updatedTimeSeries,
                    ...state.currentTimeSeries.slice(currentTimeSeriesItemIndex + 1)
                ]
            };
        }

        case actionTypes.ADD_LIMIT: {
            const { currentTimeSeriesUniqId, limit } = action.payload;

            const currentTimeSeriesItemIndex = state.currentTimeSeries.findIndex(item => item.uniqId === currentTimeSeriesUniqId);
            const correspondingTimeSeriesItem = state.timeSeries.timeSeriesList.find(item => item.uniqId === currentTimeSeriesUniqId);

            const updatedCurrentTimeSeries = {
                ...state.currentTimeSeries[currentTimeSeriesItemIndex],
                limits: [...state.currentTimeSeries[currentTimeSeriesItemIndex].limits, { ...limit, isModified: true }]
            };

            if (correspondingTimeSeriesItem !== undefined) {
                const isModified = !isEqual(updatedCurrentTimeSeries.limits, correspondingTimeSeriesItem.limits);
                updatedCurrentTimeSeries.isModified = isModified;
            } else {
                updatedCurrentTimeSeries.isModified = true;
            }

            return {
                ...state,
                currentTimeSeries: [
                    ...state.currentTimeSeries.slice(0, currentTimeSeriesItemIndex),
                    updatedCurrentTimeSeries,
                    ...state.currentTimeSeries.slice(currentTimeSeriesItemIndex + 1)
                ]
            };
        }

        case actionTypes.DISCARD_LIMIT: {
            const { currentTimeSeriesUniqId } = action.payload;
            const updatedCurrentTimeSeries = handleDiscardLimits(state, action.payload);

            // Find the index of the currentTimeSeries item to update
            const currentTimeSeriesItemIndex = state.currentTimeSeries.findIndex(item => item.uniqId === currentTimeSeriesUniqId);
            if (currentTimeSeriesItemIndex !== -1) {
                // Update state with the updated currentTimeSeries item
                return {
                    ...state,
                    currentTimeSeries: [
                        ...state.currentTimeSeries.slice(0, currentTimeSeriesItemIndex),
                        updatedCurrentTimeSeries,
                        ...state.currentTimeSeries.slice(currentTimeSeriesItemIndex + 1)
                    ]
                };
            } else {
                // Handle the case where currentTimeSeriesItem is not found
                return state;
            }
        }


        case actionTypes.REMOVE_SIGNAL: {
            const { currentTimeSeriesUniqId } = action.payload;
            return {
                ...state,
                currentTimeSeries: state.currentTimeSeries.filter(
                    (item) => item.uniqId !== currentTimeSeriesUniqId
                ),
            };
        }
        case actionTypes.CANCEL: {
            // Create a new currentTimeSeries array based on timeSeriesList
            const newCurrentTimeSeries = state.timeSeries?.timeSeriesList;

            // Update the state with the new currentTimeSeries array
            return {
                ...state,
                currentTimeSeries: newCurrentTimeSeries
            };
        }
        case actionTypes.POST_LIMIT_REQUEST:
            return {
                ...state,
                timeSeries: {
                    ...state.timeSeries,
                    error: null,
                    isLoading: apiCallStatus.LOADING,
                }
            };
        case actionTypes.POST_LIMIT_SUCCESS:
            return {
                ...state,
                timeSeries: {
                    ...state.timeSeries,
                    isLoading: apiCallStatus.SUCCESS,
                    error: null
                }
            };
        case actionTypes.POST_LIMIT_FAILURE:
            return {
                ...state,
                timeSeries: {
                    ...state.timeSeries,
                    isLoading: apiCallStatus.ERROR,
                    // error: action.error
                }
            };
        case actionTypes.HANDLE_SIGNAL_UPDATE: {
            const updatedItem = action.payload;
            const updatedItemIndex = state.currentTimeSeries.findIndex(item => item.uniqId === updatedItem.uniqId);

            if (updatedItemIndex !== undefined) {
                // Create a new array with the updated item
                const updatedCurrentTimeSeries = [
                    ...state.currentTimeSeries.slice(0, updatedItemIndex),
                    updatedItem,
                    ...state.currentTimeSeries.slice(updatedItemIndex + 1)
                ];

                return {
                    ...state,
                    currentTimeSeries: updatedCurrentTimeSeries
                };
            }

            // If the item is not found, return the state unchanged
            return state;
        }

        case actionTypes.HANDLE_SUGGEST_LIMIT_MODAL:
            return {
                ...state,
                showSuggestLimitModal: action.show
            };
        case actionTypes.SELECTED_TIMESERIES_FOR_SUGGESTION:
            return {
                ...state,
                selectedTimeSeriesForSuggestion: action.payload
            };
        case actionTypes.GET_SUGGESTED_LIMIT_DATA:
            return {
                ...state,
                suggestedLimit: {
                    data: null,
                    isLoading: true
                }
            };
        case actionTypes.SET_SUGGESTED_LIMIT_DATA: {
            const suggestedLimits = mergeSuggestedLimits(action.payload);
            let updatedTimeSeries = state.selectedTimeSeriesForSuggestion;
            if (suggestedLimits?.length) {
                const updatedLimits = suggestedLimits?.map((msl) => {
                    const limit = state.selectedTimeSeriesForSuggestion?.limits?.find((lmt) => lmt.limitDirection === msl.limitDirection && lmt.limitLevel === msl.limitLevel);
                    if (limit) {
                        return { ...limit, hasCurrentLimit: true, suggestedLimit: { ...msl } };
                    } else {
                        return { ...msl, hasCurrentLimit: false, suggestedLimit: { ...msl } };
                    }
                });
                updatedTimeSeries = {
                    ...state.selectedTimeSeriesForSuggestion,
                    limits: orderBy(updatedLimits, ['limitDirection'], ['desc'])
                };
            }
            const { convertedTimeseries } = handleUnitConversionSuggestedLimits(updatedTimeSeries);
            return {
                ...state,
                selectedTimeSeriesForSuggestion: convertedTimeseries,
                suggestedLimit: {
                    data: action.payload,
                    isLoading: false
                }
            };
        }
        case actionTypes.UPDATE_SELECTED_SUGGESTED_SIGNAL: {
            const { updatedTimeSeries } = updateSuggestedLimitReducerData(state, action);
            return {
                ...state,
                selectedTimeSeriesForSuggestion: updatedTimeSeries
            };
        }
        case actionTypes.UPDATE_LIMIT_SELECTED_TIMESERIES: {
            const { updatedTimeSeriesList } = updateSelectedTimeSeriesInListReducerData(state, action);
            return {
                ...state,
                currentTimeSeries: updatedTimeSeriesList
            };
        }
        case actionTypes.RESET_SUGGESTED_CURRENT_LIMITS: {
            let selectedTimeSeries = state.selectedTimeSeriesForSuggestion;
            const originalTimeSeries = state.currentTimeSeries?.find((cts) => cts?.uniqId === selectedTimeSeries?.uniqId);
            selectedTimeSeries = {
                ...selectedTimeSeries,
                limits: selectedTimeSeries?.limits?.map((lmt) => {
                    return {
                        ...lmt,
                        limitValue: originalTimeSeries?.limits?.find((ots) => ots?.uniqId === lmt?.uniqId)?.limitValue
                    };
                })
            };
            return {
                ...state,
                selectedTimeSeriesForSuggestion: selectedTimeSeries
            };
        }

        case actionTypes.SET_EXPANDED_SIGNALS: {
            return {
                ...state,
                expandedSignals: action.payload
            };
        }

        case actionTypes.GET_DEFAULT_LIMITS: {
            return {
                ...state,
                defaultLimit: {
                    data: null,
                    isLoading: apiCallStatus.LOADING
                }
            };
        }

        case actionTypes.SET_DEFAULT_LIMIT_DATA: {
            return {
                ...state,
                defaultLimit: {
                    data: action.payload,
                    isLoading: apiCallStatus.SUCCESS
                }
            };
        }

        case actionTypes.UPDATE_DEFAULT_LIMITS: {
            const { updatedTimeSeriesList } = updateDefaultLimitsToCurrentLimits(state, action);
            return {
                ...state,
                currentTimeSeries: updatedTimeSeriesList
            };
        }

        case actionTypes.RESET_STATE:
            return {
                ...initialState,
                expandedSignals: state.expandedSignals
            };
        default:
            return state;
    }
};

export default reducer;
