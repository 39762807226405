import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { useNewCapability } from 'sharedHooks';
import { ToggleButton, TimeSelectorRow, GeneralButton } from 'sharedComponents';
import { LoadMeasurement } from './LoadMeasurement';
import { CollectRawData } from './CollectRawData';
import {
	opButtons,
	multiChartToggleOptions,
	multiChartOptions
} from '../constants';
import {
	formatMultiToggleOptions,
} from './helpers';
import useUserCapability from 'sharedHooks/useUserCapability';

import './style.scss';
import { NotificationTypeCode } from 'helpers/constants';
import { setLastWeekDates, check365Day } from '../../../../PowertrainAssetDetails/helpers';

const OperationalParametersHeaderComponent = ({
	model: {
		dateSelection,
		hasRawData,
		activeTab,
		multiChart,
		isCMDAsset,
		hasRawDataV2
	},
	actions: {
		downloadRawData,
		downloadRawDataV2,
		setActiveTab,
		setSelectedDates,
		setActiveTrendTab
	},
	toggleOptions,
}) => {
	const { t: translate } = useTranslation();
	const newCapability = useNewCapability();
	const history = useHistory();
	const loadMeasurementOnDemandCapabilityAvailable = newCapability?.hasLoadMeasurementOnDemandCapability();
	const show365Days = activeTab !== opButtons.scatterPlot && activeTab !== opButtons.histogram;
	const collectRawDataCapabilityAvailable = newCapability?.hasCollectRawDataCapability();
	const trendHistory365daysAvailable = newCapability?.hasTrendHistory365DaysAvailable() && show365Days ? 365 : 90;
	const [isLoadMeasurementDialogVisible, setIsLoadMeasurementDialogVisible] = useState(false);
	const [isCollectRawDataDialogVisible, setIsCollectRawDataDialogVisible] = useState(false);
	const hasTrendHistory365DaysAvailable = newCapability?.hasTrendHistory365DaysAvailable() && show365Days;
	const userCapabilities = useUserCapability();

	useEffect(() => {
		toggleOptions.length && !toggleOptions.some(option => option.value === activeTab) && setActiveTab(toggleOptions[0].value);
	}, [toggleOptions]);


	const onToggleLoadMeasurementDialog = () => {
		setIsLoadMeasurementDialogVisible(!isLoadMeasurementDialogVisible);
	};

	const onToggleCollectRawDataDialog = () => {
		setIsCollectRawDataDialogVisible(!isCollectRawDataDialogVisible);
	};

	const handleSelectDate = (from, to) => {
		if (from && to) {
			setSelectedDates(from, to);
		}
	};

	useEffect(() => {
		return () => {
			// setLastWeekDates(); // COMMENTING AS PART OF BUG-428805
			setActiveTab(opButtons.trends);
			setActiveTrendTab(multiChartOptions.SINGLE);
		};
	}, []);

	const handleTabSelection = (tab) => {
		setActiveTab(tab);
		if (tab !== opButtons.trends && check365Day(dateSelection.from, dateSelection.to)) {
			setLastWeekDates(setSelectedDates);
		}
	};

	const handleTrendTabSelection = (tab) => {
		setActiveTrendTab(tab);
	};

	// SET vibrationFFT TAB FROM NOTIFICATION
	useEffect(() => {
		if (history?.location?.state?.notificationData) {
			const typeCode = history?.location?.state?.notificationData?.notificationTypeCode;
			if (typeCode === NotificationTypeCode.LoadMeasurementProcessed || typeCode === NotificationTypeCode.LoadMeasurementRequestCompleted) {
				setActiveTab(opButtons.vibrationFFT);
			}
		}
	}, [history?.location?.state]);

	return <>
		<LoadMeasurement show={isLoadMeasurementDialogVisible} close={onToggleLoadMeasurementDialog} />
		<CollectRawData show={isCollectRawDataDialogVisible} close={onToggleCollectRawDataDialog} />
		<Row className='tab-actions-container justify-content-end'>
			<Col xl lg={12}>
				<div className='toggle-buttons'>
					<ToggleButton
						options={toggleOptions}
						onSelected={handleTabSelection}
						selectedValue={activeTab}
					/>
					{userCapabilities.hasMultiGraphTabViewCapability() &&
						activeTab === opButtons.trends &&
						isCMDAsset &&
						<ToggleButton
							options={formatMultiToggleOptions(multiChartToggleOptions)}
							onSelected={handleTrendTabSelection}
							selectedValue={multiChart}
						/>
					}
				</div>
			</Col>
			{
				loadMeasurementOnDemandCapabilityAvailable &&
				<Col md='auto'>
					<GeneralButton
						type='normal'
						text={translate('ABB.Powertrain.Frontend.opLoadMeasurmentBtn')}
						onClick={onToggleLoadMeasurementDialog}
					/>
				</Col>
			}
			{
				[opButtons.vibrationFFT, opButtons.vibrationFFTV2].includes(activeTab) ?
					<>
						{
							collectRawDataCapabilityAvailable &&
							<Col md='auto'>
								<GeneralButton
									type='normal'
									text={translate('ABB.Powertrain.Frontend.opCollectRawDataBtn')}
									onClick={onToggleCollectRawDataDialog}
								/>
							</Col>
						}
						{
							hasRawData && activeTab === opButtons.vibrationFFT &&
							<Col md='auto'>
								<GeneralButton
									type='normal'
									text={translate('ABB.Powertrain.Frontend.opDownloadRawDataBtn')}
									onClick={() => downloadRawData()}
								/>
							</Col>
						}
						{
							hasRawDataV2 && activeTab === opButtons.vibrationFFTV2 &&
							<Col md='auto'>
								<GeneralButton
									type='normal'
									text={translate('ABB.Powertrain.Frontend.opDownloadRawDataBtn')}
									onClick={() => downloadRawDataV2()}
								/>
							</Col>
						}
					</> : null
			}
			{
				![opButtons.vibrationFFT, opButtons.vibrationFFTV2].includes(activeTab) &&
				<Col className='col-xl-auto col-lg col-sm-auto'>
					<TimeSelectorRow
						fromDate={dateSelection.from}
						toDate={dateSelection.to}
						label=''
						handleSelectDate={handleSelectDate}
						maxDaysRange={trendHistory365daysAvailable}
						maxDaysBack={trendHistory365daysAvailable}
						preSelectedDays={7}
						numberOfMonths={2}
						footerIntervalText={hasTrendHistory365DaysAvailable ? translate('ABB.Powertrain.Frontend.datePickerMax365DaysNote') : translate('ABB.Powertrain.Frontend.datePickerMax90DaysNote')}
					/>
				</Col>
			}
		</Row>
	</>;
};

OperationalParametersHeaderComponent.propTypes = {
	model: PropTypes.shape({
		dateSelection: PropTypes.shape({
			from: PropTypes.string,
			to: PropTypes.string
		}),
		hasRawData: PropTypes.bool,
		activeTab: PropTypes.string,
		multiChart: PropTypes.string,
		isCMDAsset: PropTypes.bool
	}),
	actions: PropTypes.shape({
		downloadRawData: PropTypes.func,
		setActiveTab: PropTypes.func,
		setSelectedDates: PropTypes.func,
		setActiveTrendTab: PropTypes.func
	}),
	toggleOptions: PropTypes.array,
};

export default OperationalParametersHeaderComponent;
