import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralDialog, LoadingIndicator } from 'sharedComponents';
import { LEVEL, LIMIT_SYMBOL } from './constants';
import { options } from './Limit';
import { IconWarningType } from 'svgIcons/MotionPortalIcons';
import { apiCallStatus } from 'helpers/constants';

const ResetToDefaultModal = ({ showReset, setShowReset, ...props }) => {
    const { t: translate } = useTranslation();
    const { defaultLimitValues, defaultLimtsLoading, item, actions } = props;
    const InValidDefaultLimit = defaultLimitValues?.length && defaultLimitValues[0]?.limits?.length && defaultLimitValues[0]?.limits?.find((dl) => dl?.defaultValue || dl?.defaultValue === 0);

    const handleDefaultReset = () => {
        const defaultLimitsResponse = defaultLimitValues[0];
        const payload = {
            ...item,
            resetToDefault: true, // Mark as reset to default
            limits: item.limits
                .map((limit) => {
                    const defaultLimit = defaultLimitsResponse?.limits?.find(
                        (dl) => dl?.currentValue === limit?.limitValue
                    );
                    return defaultLimit
                        ? {
                            ...limit,
                            resetToDefault: true, // Mark as reset to default
                            limitValue: defaultLimit?.defaultValue,
                            limitDirection: defaultLimit?.limitDirection,
                            limitLevel: defaultLimit?.limitLevel,
                        }
                        : { ...limit };
                })
                .filter((limit) => limit?.resetToDefault || limit?.isUserLimit), // Keep only default and personal limits
        };
        actions.updateDefaultLimits(payload);
        setShowReset(false); // Close the modal
    };

    return (
        <GeneralDialog
            modalClassName='reset-default-limits'
            show={showReset}
            close={() => setShowReset(false)}
            title={translate('ABB.Powertrain.Frontend.ResetDefaultLimit', { Limit: item?.displayName })}
            cancelButtonProps={{
                text: translate('ABB.Powertrain.Frontend.cancelLabel'),
                onClick: () => setShowReset(false)
            }}
            acceptButtonProps={{
                text: translate('ABB.Powertrain.Frontend.Reset'),
                onClick: handleDefaultReset,
                disabled: !InValidDefaultLimit
            }}
            closeButton={true}
            persistent={false}
        >
            <div className='default-info-content'>
                <IconWarningType width={24} height={24} />
                <span>{translate('ABB.Powertrain.Frontend.ResetDefalutLimitInfo')}</span>
            </div>
            {defaultLimtsLoading === apiCallStatus.LOADING && <LoadingIndicator />}
            {defaultLimitValues?.length && <>
                <div className='review-default'><span>{translate('ABB.Powertrain.Frontend.ReviewDefault')}</span></div>
                {defaultLimitValues[0]?.limits?.map(limit =>
                    <div className='limit-box-default mb-2' key={limit?.id}>
                        <div className='limit-level-container d-flex align-items-center'>
                            {options?.find((o) => o?.label === limit?.limitLevel)?.prefixIcon} <span className='ms-2'>{options?.find((o) => o?.label === limit?.limitLevel)?.label}</span>
                        </div>
                        <div className='limit-value-fields'>
                            <div className='limit-value-uom disabled'>
                                <input
                                    type='text'
                                    placeholder={translate('ABB.Powertrain.Frontend.inputValue')}
                                    value={`${limit?.limitDirection === LEVEL.UpperLimit || limit?.limitDirection === LEVEL.Upper ? LIMIT_SYMBOL.Greater : LIMIT_SYMBOL.Lower} ${limit?.defaultValue === 0 ? '0' : limit?.defaultValue || ''}`}
                                    name='limitValue'
                                    disabled={true} />
                                {item?.unit && <div className='uom-text'>{item?.unit}</div>}
                            </div>
                        </div>
                    </div>)}
            </>}
        </GeneralDialog >
    );
};

ResetToDefaultModal.propTypes = {
    showReset: PropTypes.any,
    setShowReset: PropTypes.any,
    defaultLimitValues: PropTypes.array,
    defaultLimtsLoading: PropTypes.string,
    item: PropTypes.object,
    actions: PropTypes.shape({
        updateDefaultLimits: PropTypes.func,
    }),
};

export default ResetToDefaultModal;
