import { isEqual, omit } from 'lodash';
import { limitTypes } from './components/helper';
import { baseToCustomUOM, getMeasurementUnits, getMeasurementUnitSymbol } from 'helpers/unitHelper';

export const updateLimitReducerData = (state, action) => {
    const { limitUniqId, field, value, currentTimeSeriesUniqId } = action.payload;

    // Find the currentTimeSeries item to update
    const currentTimeSeriesItemIndex = state.currentTimeSeries?.findIndex(item => item.uniqId === currentTimeSeriesUniqId);
    const correspondingTimeSeriesItem = state.timeSeries?.timeSeriesList?.find(item => item.uniqId === currentTimeSeriesUniqId);

    // Update the currentTimeSeries item with the payload
    const updatedCurrentTimeSeries = {
        ...state.currentTimeSeries[currentTimeSeriesItemIndex],
        limits: state.currentTimeSeries[currentTimeSeriesItemIndex]?.limits?.map(limit => {
            if (limit.uniqId === limitUniqId) {
                const updatedLimit = { ...limit, [field]: value };
                const initialLimit = correspondingTimeSeriesItem?.limits?.find(initialLimit => initialLimit.uniqId === limitUniqId);
                const isModified = !isEqual(omit(updatedLimit, 'isModified'), omit(initialLimit, 'isModified'));
                return { ...updatedLimit, isModified };
            }
            return limit;
        })
    };

    if (correspondingTimeSeriesItem !== undefined) {
        // Compare with correspondingTimeSeriesItem to determine isModified
        const isModified = !isEqual(
            updatedCurrentTimeSeries?.limits?.map(limit => {
                return {
                    ...limit,
                    isModified: undefined // Exclude isModified from the comparison
                };
            }),
            correspondingTimeSeriesItem?.limits?.map(limit => ({
                ...limit,
                isModified: undefined // Exclude isModified from the comparison
            }))
        );
        // Update isModified in updatedCurrentTimeSeries
        updatedCurrentTimeSeries.isModified = isModified;
    } else {
        updatedCurrentTimeSeries.isModified = true;
    }

    let isAnySameLimit = false;
    const sameValueLimits = [];
    let updatedTimeSeries = {
        ...updatedCurrentTimeSeries,
        limits: updatedCurrentTimeSeries?.limits?.map((limit) => {
            // if (!limit?.isUserLimit) { // EXCLUDE PERSONAL LIMITS
            const sameLimits = updatedCurrentTimeSeries?.limits?.filter((sl) => +sl?.limitValue?.toString() === +limit?.limitValue?.toString() && sl?.uniqId !== limit?.uniqId); // add && !sl?.isUserLimit if needs to exclude personal limits with same limit values
            if (sameLimits?.length > 0) {
                isAnySameLimit = true;
                for (let i = 0; i < sameLimits?.length; i++) {
                    sameValueLimits?.push({ ...sameLimits[i], isSameLimit: true });
                }
            }
            // }
            return { ...limit, isSameLimit: false };
        })
    };
    if (isAnySameLimit) {
        // UPDATE SAME VALUES LIMITS
        updatedTimeSeries = {
            ...updatedTimeSeries,
            limits: updatedTimeSeries?.limits?.map((lmt) => {
                const isLimitSame = sameValueLimits?.find((svl) => svl?.uniqId === lmt?.uniqId);
                if (isLimitSame) {
                    return isLimitSame;
                }
                return lmt;
            })
        };
    }

    return { updatedTimeSeries, currentTimeSeriesItemIndex };
};

export const updateSuggestedLimitReducerData = (state, action) => {
    const { limitUniqId, field, value } = action.payload;
    const updatedTimeSeries = {
        ...state.selectedTimeSeriesForSuggestion,
        limits: state.selectedTimeSeriesForSuggestion?.limits?.map((l) => {
            if (l.uniqId === limitUniqId) {
                return {
                    ...l,
                    [field]: value,
                    isModified: true
                };
            }
            return l;
        })
    };
    return { updatedTimeSeries };
};

export const updateSelectedTimeSeriesInListReducerData = (state, action) => {
    const signal = action.payload;
    const updatedTimeSeriesList = state.currentTimeSeries?.map((ct) => {
        if (ct.uniqId === signal.uniqId) {
            return {
                ...signal,
                isModified: true,
                limits: signal.limits?.map((ctl) => {
                    return {
                        ...ctl,
                        limitValue: action.selectedLimitType === limitTypes[0] ? ctl?.limitValue : ctl.suggestedLimit?.limitValue
                    };
                })
            };
        }
        return ct;
    });

    return { updatedTimeSeriesList };
};

export const mergeSuggestedLimits = (payload) => {
    const limits = [];
    if (payload && payload?.calculatedLimit) {
        Object.entries(payload?.calculatedLimit).map(([key, value]) => {
            if (key === 'highErrorThreshold' && value) {
                limits.push({
                    uniqId: crypto.randomUUID(),
                    limitLevel: 'Alarm',
                    limitValue: value,
                    limitDirection: 'UpperLimit',
                    key
                });
            }
            if (key === 'lowErrorThreshold' && value) {
                limits.push({
                    uniqId: crypto.randomUUID(),
                    limitLevel: 'Alarm',
                    limitValue: value,
                    limitDirection: 'LowerLimit',
                    key
                });
            }
            if (key === 'highWarningThreshold' && value) {
                limits.push({
                    uniqId: crypto.randomUUID(),
                    limitLevel: 'Alert',
                    limitValue: value,
                    limitDirection: 'UpperLimit',
                    key
                });
            }
            if (key === 'lowWarningThreshold' && value) {
                limits.push({
                    uniqId: crypto.randomUUID(),
                    limitLevel: 'Alert',
                    limitValue: value,
                    limitDirection: 'LowerLimit',
                    key
                });
            }
        });
    }
    return limits;
};

export const handleUnitConversionSuggestedLimits = (timeseries) => {
    const userMeasurementUnitGroups = getMeasurementUnits();
    const convertedTimeseries = {
        ...timeseries,
        limits: timeseries?.limits?.map((lmt) => {
            const valueObj = baseToCustomUOM?.convertAndFormatWithGroups(
                timeseries?.timeseriesUnitGroupID,
                lmt?.suggestedLimit?.limitValue,
                userMeasurementUnitGroups);
            return {
                ...lmt,
                suggestedLimit: {
                    ...lmt?.suggestedLimit,
                    limitValue: valueObj?.measurementValue % 1 === 0 ? valueObj?.measurementValue : valueObj?.measurementValue?.toFixed(2)
                }
            };
        })
    };
    return { convertedTimeseries };
};

export const handleDiscardLimits = (state, payload) => {
    const { currentTimeSeriesUniqId, isUserLimit } = payload;

    // Find the index of the currentTimeSeries item to update
    const currentTimeSeriesItemIndex = state.currentTimeSeries.findIndex(item => item.uniqId === currentTimeSeriesUniqId);
    const currentTimeSeriesItem = state.currentTimeSeries?.find(item => item?.uniqId === currentTimeSeriesUniqId);

    // Find the corresponding item in timeSeriesList for comparison
    const correspondingTimeSeriesItem = state.timeSeries.timeSeriesList.find(item => item.uniqId === currentTimeSeriesUniqId);
    let updatedCurrentTimeSeries = correspondingTimeSeriesItem || currentTimeSeriesItem;

    if (correspondingTimeSeriesItem) {
        // If correspondingTimeSeriesItem exists, copy it back to currentTimeSeries
        updatedCurrentTimeSeries = {
            ...currentTimeSeriesItem,
            limits: currentTimeSeriesItem?.limits?.map((ctl) => {
                if (ctl?.isUserLimit === isUserLimit) {
                    const oldLimit = correspondingTimeSeriesItem?.limits?.find((lmt) => ctl?.uniqId === lmt?.uniqId);
                    if (oldLimit) {
                        return { ...oldLimit, isModified: false };
                    } else {
                        return {
                            ...ctl,
                            limitValue: '',
                            limitLevel: '',
                            limitDirection: '',
                            isModified: false
                        };
                    }
                } else {
                    return ctl;
                }
            })?.filter((item) => item?.uniqId),
            // isModified: correspondingTimeSeriesItem?.limits?.find((lmt) => lmt?.isModified) ? true : false // Assuming the copied item is not modified
        };
    } else {
        // If correspondingTimeSeriesItem does not exist, reset limit values to empty string
        updatedCurrentTimeSeries = {
            ...state.currentTimeSeries[currentTimeSeriesItemIndex],
            limits: state.currentTimeSeries[currentTimeSeriesItemIndex]?.limits.map(limit => {
                if (isUserLimit === limit?.isUserLimit) {
                    return {
                        ...limit,
                        limitValue: '',
                        limitLevel: '',
                        limitDirection: '',
                        isModified: false
                    };
                } else {
                    return limit;
                }
            }),
            // isModified: state.currentTimeSeries[currentTimeSeriesItemIndex]?.limits?.find((l) => l?.isModified) ? true : false
        };
    }

    updatedCurrentTimeSeries = {
        ...updatedCurrentTimeSeries,
        isModified: updatedCurrentTimeSeries?.limits?.find((l) => l?.isModified || l?.limitValue === '') ? true : false
    };

    return updatedCurrentTimeSeries;
};

export const handleDefaultUnitConversion = (response, limitData) => {
    const userMeasurementUnitGroups = getMeasurementUnits();
    response?.timeseries?.map((ts) => {
        const timeseriesUnitGroupID = limitData?.timeseriesUnitGroupID;
        const unit = getMeasurementUnitSymbol(timeseriesUnitGroupID);
        ts.limits?.map((lmt) => {
            const valueObj = baseToCustomUOM?.convertAndFormatWithGroups(
                timeseriesUnitGroupID,
                lmt?.defaultValue,
                userMeasurementUnitGroups);
            lmt.defaultValue = valueObj?.measurementValue % 1 === 0 ? valueObj?.measurementValue : valueObj?.measurementValue?.toFixed(2);
        });
        ts.unit = unit;
    });

    return response;
};

export const updateDefaultLimitsToCurrentLimits = (state, action) => {
    const signal = action.data;
    const updatedTimeSeriesList = state.currentTimeSeries?.map((ct) => {
        if (ct.uniqId === signal.uniqId) {
            return { ...signal, isModified: true };
        }
        return ct;
    });
    return { updatedTimeSeriesList };
};
