import React, { useMemo, useCallback, useState } from 'react';
import ContextMenuComponent from './ContextMenuComponent';
import { GeneralButton } from 'sharedComponents/GeneralButton';
import { IconClose, IconPlus, IconWarning, IconSuggestion } from 'svgIcons/MotionPortalIcons';
import { getTranslatedKPINameV2, translate } from 'helpers/translateHelper';
import { GeneralSelect } from 'sharedComponents/GeneralSelect';
import { useDispatch } from 'react-redux';
import { addLimit, removeSignal, updateSignal, discardLimit, handleSignalUpdate, postLimitRequest, cancel, getDefaultLimitsData } from '../actions';
import { KPI_ERROR, KPI_TYPE, LIMIT_MENU_OPTIONS, TRENDS } from './constants';
import { getContextMenuOptions } from './helper';
import { GeneralDialog } from 'sharedComponents/GeneralDialog';
import { map as lmap, find as lfind, filter as lfilter } from 'lodash';
import OffsetModal from './OffestModal';
import { ASSET_TYPES_MS } from 'helpers/constants';
import { InfoButtonWithOnlineHelp } from 'sharedComponents/InfoButtonWithOnlineHelp';
import colors from 'theme/_colors.scss';
import { getMeasurementUnitSymbol } from 'helpers/unitHelper';
import ResetToDefaultModal from './ResetToDefaultModal';

//TODO  : - : this will be removed when timeseriesKey is sent as part of limitConfig data
const getTimeSeriesKey = (kpiListData, keyName) => {
    const additionalTrendList = (kpiListData ?? [])?.filter(item => item?.timeseriesGroupName === 'AdditionalTrends');
    const keyObj = additionalTrendList[0]?.timeseries?.filter(item => item?.timeseriesKey === keyName); // USE timeseriesNameTextKey IF NOT WORK
    return keyObj[0]?.timeseriesKey || '';
};

const getPayload = (item, result, kpiList) => {
    const timeseries = result[0]?.timeseries[0];

    return {
        ...item,
        isTrend: result[0]?.timeseriesGroupCode === KPI_TYPE.Trend,
        timeseriesKey: result[0]?.timeseriesGroupCode === KPI_TYPE.Trend ? getTimeSeriesKey(kpiList, timeseries?.timeseriesKey) : timeseries?.timeseriesKey, // USE timeseriesNameTextKey IN getTimeSeriesKey IF NOT WORK
        trendKey: result[0]?.timeseriesGroupCode !== KPI_TYPE.Trend ? '' : timeseries?.timeseriesKey,
        displayNameTextKey: timeseries?.timeseriesNameTextKey,
        appendCodeToTranslation: timeseries?.appendCodeToTranslation,
    };
};

const getDefaultPayload = (item) => {
    return {
        timeseriesKey: item?.timeseriesKey,
        trendkey: item?.trendKey
    };
};


const LimitTitle = (props) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [showOffset, setShowOffset] = useState(false);
    const [showReset, setShowReset] = useState(false);
    const isUserLimit = props.isUserLimit;

    const handleChange = (selected, field) => {
        if (props?.item?.isSignalError) {
            dispatch(updateSignal(props?.item?.uniqId, KPI_ERROR.isSignalError, false));
        }
        dispatch(updateSignal(props?.item?.uniqId, field, selected.title));
    };

    const handleUpdate = (field, value) => {
        dispatch(updateSignal(props?.item?.uniqId, field, value));
    };

    const handleAddLimit = (uniqId) => {
        if (props?.defaultLimits?.length >= 3 && !isUserLimit) {
            props.setShowMaxLimitMessage(true);
        }
        dispatch(addLimit(uniqId, isUserLimit));
    };

    const handleDiscard = (uniqId) => {
        if (props?.item?.limits?.length) {
            dispatch(discardLimit(uniqId, isUserLimit));
        } else {
            dispatch(cancel(uniqId));
        }
    };

    const handleRemoveSignal = () => {
        if (props?.item?.timeseriesKey) {
            const payload = [{ ...props?.item, limits: [] }];
            dispatch(postLimitRequest(props?.asset?.id, payload));
        } else {
            dispatch(removeSignal(props?.item?.uniqId));
        }
    };

    const handleDefaultLimt = () => {
        const payload = getDefaultPayload(props?.item);
        dispatch(getDefaultLimitsData(props?.asset?.id, [payload], props?.item));
        setShowReset(true);
    };

    const handleTimeSeries = useCallback((value) => {
        const data = lfilter(props?.model?.limitKPI?.kpiList ?? [], item => {
            return lfind(item?.timeseries, series => {
                return series?.timeseriesNameTextKey === value;
            });
        });

        const result = lmap(data, obj => {
            return {
                ...obj,
                timeseries: lfilter(obj?.timeseries, series => {
                    return series?.timeseriesNameTextKey === value;
                })
            };
        });

        const payload = getPayload(props?.item, result, props?.model?.limitKPI?.kpiList);
        dispatch(handleSignalUpdate(payload));
    }, [props?.model?.limitKPI?.kpiList, props?.item]);

    const kpiOptionsMemo = useMemo(() => {
        const kpiList = props?.model?.limitKPI?.kpiList ?? [];

        kpiList.forEach(item => {
            if (item?.timeseriesGroupName === TRENDS) {
                item?.timeseries?.sort((a, b) => a?.timeseriesKey?.localeCompare(b?.timeseriesKey));
            }
        });

        const accumulatedKPIItems = (kpiList ?? []).reduce((acc, item) => {
            item?.timeseries?.forEach(series => {
                const title = getTranslatedKPINameV2(series);
                const id = getTranslatedKPINameV2(series);
                const key = series?.timeseriesNameTextKey;
                const minValue = series?.minValue;
                const maxValue = series?.maxValue;
                const uom = series?.unit;
                const isLimitEditable = series?.isDefaultLimitEditable;
                const timeseriesUnitGroupID = series?.timeseriesUnitGroupID;
                const timeseriesKey = series?.timeseriesKey;
                // const timeSeriesName = series.timeseriesName;

                // TODO Need to check if the translation is required with the below conditon
                // if (series.appendCodeToTranslation) {
                //     title = !series.timeseriesNameTextKey ? `${getTranslatedKPINameV2(series.timeseriesName)}` : `${series.timeseriesKey}`;
                //     id = !series.timeseriesNameTextKey ? `${series.timeseriesName}` : `${series.timeseriesKey}`;
                // }

                acc.push({ title, id, key, minValue, maxValue, uom, isLimitEditable, timeseriesUnitGroupID, timeseriesKey });
            });

            return acc;
        }, []);

        const accumulatedKeys = new Set(props?.model?.currentTimeSeries?.map(item => item?.displayNameTextKey));
        const filteredTimeseries = accumulatedKPIItems?.filter(series => !accumulatedKeys.has(series?.key));

        return filteredTimeseries;
    }, [props?.model?.limitKPI?.kpiList, props?.model?.currentTimeSeries]);

    const isAnyLimitsError = props?.item?.isSignalError || props?.item?.limits?.some((lmt) => lmt?.isSameLimit || !lmt?.limitDirection || lmt?.limitValue === undefined || lmt.limitValue === null || lmt.limitValue === '');

    const handleMenu = useCallback((value) => {
        switch (value) {
            case LIMIT_MENU_OPTIONS.REMOVE_SIGNAL: setShowModal(true);
                break;
            case LIMIT_MENU_OPTIONS.EDIT_OFFSET: setShowOffset(true);
                break;
            case LIMIT_MENU_OPTIONS.EXPORT:
                break;
            case LIMIT_MENU_OPTIONS.RESET_DEFAULT_LIMITS: handleDefaultLimt();
                break;
            default:
                break;
        }
    }, []);

    return (
        <div className='limit-title-container'>
            {/* RESET TO DEFAULT DIALOG START */}
            {showReset &&
                <div onClick={e => e.stopPropagation()}>
                    <ResetToDefaultModal {...props} showReset={showReset} setShowReset={setShowReset} />
                </div>}
            {/* RESET TO DEFAULT DIALOG END */}
            {/* REMOVE ALL LIMITS DIALOG START */}
            {showModal && <div onClick={e => e.stopPropagation()}>
                <GeneralDialog
                    show={showModal}
                    close={() => setShowModal(false)}
                    notificationType='info'
                    title={translate('ABB.Powertrain.Frontend.lblRemoveSignal')}
                    cancelButtonProps={{
                        text: translate('ABB.Powertrain.Frontend.cancelLabel'),
                        onClick: () => setShowModal(false)
                    }}
                    acceptButtonProps={{
                        text: translate('ABB.Powertrain.Frontend.lblRemove'),
                        onClick: handleRemoveSignal
                    }}
                    closeButton={true}
                    persistent={false}
                >
                    <div className='remove-signal-text' dangerouslySetInnerHTML={{ __html: translate('ABB.Powertrain.Frontend.limitRemoval') }}></div>
                </GeneralDialog>
            </div>}
            {/* REMOVE ALL LIMITS DIALOG END */}
            {showOffset && <div onClick={e => e.stopPropagation()}>
                <OffsetModal
                    showOffset={showOffset}
                    setShowOffset={setShowOffset}
                    item={props?.item}
                />
            </div>}
            <div className='limit-title-left'>
                <div className='limit-signal' onClick={e => props?.item?.displayName ? props?.handleExpandSignal(props?.item?.displayNameTextKey) : e.stopPropagation()}>
                    {isUserLimit ?
                        <div>
                            {translate('ABB.Powertrain.Frontend.personalLimitsForNotification')}
                            <InfoButtonWithOnlineHelp infoText={translate('ABB.Powertrain.Frontend.personalLimitsInfoMessage')} />
                        </div> :
                        <>
                            <GeneralSelect
                                options={kpiOptionsMemo}
                                onChange={(selected) => {
                                    const unit = getMeasurementUnitSymbol(selected?.timeseriesUnitGroupID);
                                    handleTimeSeries(selected.key, selected.title);
                                    handleChange(selected, 'displayName');
                                    handleUpdate('unit', unit);
                                    handleUpdate('minValue', selected.minValue);
                                    handleUpdate('maxValue', selected.maxValue);
                                    handleUpdate('timeseriesUnitGroupID', selected.timeseriesUnitGroupID);
                                    props.handleExpandSignal(`${selected?.key}`);
                                }}
                                isDisabled={false}
                                getOptionLabel={option => option.title}
                                getOptionValue={option => option.id}
                                menuShouldScrollIntoView={false}
                                isSearchable={true}
                                disableOnSelect
                                selectedValue={props?.item?.displayName || ''}
                                error={props?.item?.isSignalError}
                                inputMaxChars={50}
                            />
                            {props?.item?.unit && <span className='limit-uom'>({props.item.unit})</span>}
                            {props?.item?.isOffsetEnabled && props?.item?.offset && <span className='limit-pipe'>|</span>}
                            {isAnyLimitsError && <div className='d-flex justify-content-center align-items-center ms-1'> <IconWarning color={colors.red} width={16} height={16} /></div>}
                        </>
                    }
                </div>
                {props?.item?.isOffsetEnabled && props?.item?.offset && <div className='limit-offset'>
                    {translate('ABB.Powertrain.Frontend.limitOffset')}: {props?.item?.offset > 0 ? `+${props?.item?.offset}` : props?.item?.offset}
                </div>}
            </div>
            <div className='limit-title-right'>
                <div className='active-notification-count'>
                    {props.isUserLimit ?
                        <> {props.notificationCount} {translate('ABB.Powertrain.Frontend.activeNotifyMeLimits')?.toLowerCase()} <span className={`dot ${props.notificationCount > 0 ? 'active' : 'inactive'} ms-2`} /> </> :
                        props?.anyLimitEventActive ?
                            <> {translate('ABB.Powertrain.Frontend.activeNotifyMeLimits')} <span className='dot active ms-2' /> </> :
                            <>{translate('ABB.Powertrain.Frontend.inActiveNotifyMeLimits')} <span className='dot inactive ms-2' /></>}

                </div>
                {props.timeseriesLoading || props.item?.isModified && props.showDiscard && <GeneralButton
                    type='discreet'
                    icon={IconClose({ width: '14px', height: '14px', color: '#1f1f1f' })}
                    text={'Discard'}
                    className='limit-discard'
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDiscard(props?.item?.uniqId);
                    }}
                />}
                {props?.item?.timeseriesKey && props?.asset?.assetType === ASSET_TYPES_MS?.MOTOR && !isUserLimit &&
                    <div className='ms-2 me-1' onClick={e => {
                        e.stopPropagation();
                        if (!props?.expandedItem?.includes(props?.item?.displayNameTextKey) || !props?.expandedItem?.includes(`P_${props?.item?.displayNameTextKey}`)) {
                            props?.handleExpandSignal(!isUserLimit ? props?.item?.displayNameTextKey : `P_${props?.item?.displayNameTextKey}`);
                        }
                        props?.openSuggestLimitModal();
                    }}>
                        <IconSuggestion /> {translate('ABB.Powertrain.Frontend.suggestTitle')}
                    </div>
                }
                <div className={`add-limit ${!props?.timeseriesLoading && props?.defaultLimits?.length > 0 || isUserLimit ? '' : 'add-limit-disabled'} ${!props?.checkIsSmseAsset ? props?.item?.isAddingCustomLimitsAllowed === false || props?.defaultLimits?.length >= 4 ? 'add-limit-disabled' : '' : !isUserLimit ? 'add-limit-disabled' : ''}`} onClick={(e) => {
                    e.stopPropagation();
                    if (e.currentTarget.classList.contains('add-limit-disabled')) {
                        return;
                    }
                    if (!props?.expandedItem?.includes(props?.item?.displayNameTextKey) || !props?.expandedItem?.includes(`P_${props?.item?.displayNameTextKey}`)) { props?.handleExpandSignal(!isUserLimit ? props?.item?.displayNameTextKey : `P_${props?.item?.displayNameTextKey}`); }
                    handleAddLimit(props?.item?.uniqId);
                }}>
                    <IconPlus />
                    <div>{translate('ABB.Powertrain.Frontend.addLimit')}</div>
                </div>
                {!isUserLimit &&
                    <div onClick={e => e.stopPropagation()}>
                        <ContextMenuComponent
                            options={getContextMenuOptions(props, props.item?.isOffsetEnabled, [
                                {
                                    id: 'removeKpi',
                                    title: translate('ABB.Powertrain.Frontend.lblRemoveSignalBtn'),
                                },
                                {
                                    id: 'editOffset',
                                    title: translate('ABB.Powertrain.Frontend.editOffset'),
                                },
                                {
                                    id: 'reset default limits',
                                    title: translate('ABB.Powertrain.Frontend.ResetDefault'),
                                }
                            ]
                            )}
                            onSelect={handleMenu}
                            id={props?.item?.uniqId}
                            isOpen={props?.item?.uniqId === props.contextMenuId}
                            setContextMenuId={props.setContextMenuId}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

export default LimitTitle;
