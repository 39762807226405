import { LimitTypeOptions } from './components/constants';
import { actionTypes } from './constants';

export const getLimitKPI = (assetId) => {
    return {
        type: actionTypes.GET_LIMIT_KPI,
        payload: { assetId }
    };
};

export const getTimeSeries = (assetId) => {
    return {
        type: actionTypes.GET_TIME_SERIES,
        payload: { assetId }
    };
};

export const addSignal = (signal) => {
    return {
        type: actionTypes.ADD_SIGNAL,
        payload: signal
    };
};

export const discardLimit = (currentTimeSeriesUniqId, isUserLimit) => {
    return {
        type: actionTypes.DISCARD_LIMIT,
        payload: { currentTimeSeriesUniqId, isUserLimit }
    };
};


export const updateSignal = (uniqId, field, value) => {
    return {
        type: actionTypes.UPDATE_SIGNAL,
        payload: { uniqId, field, value }
    };
};

export const limitTypeChange = (item, value) => {
    const selectedOption = LimitTypeOptions.find(option => option.value === value);

    if (!selectedOption) {
        return {
            type: actionTypes.LIMIT_TYPE_CHANGE,
            payload: {
                item: item,
            },
        };
    }

    const type = selectedOption.type;
    const direction = selectedOption.limitDirection;

    const updatedLimits = type.map((limitLevel, i) => ({
        uniqId: crypto.randomUUID(),
        limitLevel: limitLevel,
        limitValue: '',
        limitDirection: direction[i],
        isUserLimit: false,
        isModified: true
    }));

    const updatedItem = {
        ...item,
        limits: [...item.limits, ...updatedLimits],
    };

    return {
        type: actionTypes.LIMIT_TYPE_CHANGE,
        payload: {
            updatedItem: updatedItem,
        },
    };
};

export const deleteLimit = (item) => {
    return {
        type: actionTypes.DELETE_LIMIT,
        payload: {
            item,
        },
    };
};

export const updateLimit = (limitUniqId, field, value, currentTimeSeriesUniqId) => {
    return {
        type: actionTypes.UPDATE_LIMIT,
        payload: {
            limitUniqId,
            field,
            value,
            currentTimeSeriesUniqId
        },
    };
};

export const addLimit = (currentTimeSeriesUniqId, isUserLimit) => {
    return {
        type: actionTypes.ADD_LIMIT,
        payload: {
            currentTimeSeriesUniqId,
            limit: {
                uniqId: crypto.randomUUID(),
                isUserLimit,
                isDefault: false,
                notificationEnabled: false
            },
        },
    };
};

export const removeSignal = (currentTimeSeriesUniqId) => {
    return {
        type: actionTypes.REMOVE_SIGNAL,
        payload: {
            currentTimeSeriesUniqId,
        },
    };
};

export const cancel = () => {
    return {
        type: actionTypes.CANCEL,
    };
};

export const resetState = () => ({
    type: actionTypes.RESET_STATE
});

export const postLimitRequest = (assetId, configData) => ({
    type: actionTypes.POST_LIMIT_REQUEST,
    payload: { assetId, configData }
});

export const postLimitSuccess = (response) => ({
    type: actionTypes.POST_LIMIT_SUCCESS,
    payload: response
});

export const postLimitFailure = (error) => ({
    type: actionTypes.POST_LIMIT_FAILURE,
    error: error
});

export const handleSignalUpdate = (payload) => ({
    type: actionTypes.HANDLE_SIGNAL_UPDATE,
    payload
});

export const handleShowSuggestLimitModal = (show) => ({
    type: actionTypes.HANDLE_SUGGEST_LIMIT_MODAL,
    show
});

export const handleSelectTimeSeriesForSuggestion = (payload) => ({
    type: actionTypes.SELECTED_TIMESERIES_FOR_SUGGESTION,
    payload
});

export const getSelectedTimeSeriesSuggestedData = (payload) => ({
    type: actionTypes.GET_SUGGESTED_LIMIT_DATA,
    payload
});

export const setSelectedTimeSeriesSuggestedData = (payload) => ({
    type: actionTypes.SET_SUGGESTED_LIMIT_DATA,
    payload
});

export const updateCurrentLimit = (limitUniqId, field, value) => ({
    type: actionTypes.UPDATE_SELECTED_SUGGESTED_SIGNAL,
    payload: {
        limitUniqId,
        field,
        value
    }
});

export const updateSelectedTimeSeriesSuggestedData = (payload, selectedLimitType) => ({
    type: actionTypes.UPDATE_LIMIT_SELECTED_TIMESERIES,
    payload,
    selectedLimitType
});

export const resetSuggestedCurrentLimits = () => {
    return {
        type: actionTypes.RESET_SUGGESTED_CURRENT_LIMITS
    };
};

export const setExpandedSignals = (payload) => ({
    type: actionTypes.SET_EXPANDED_SIGNALS,
    payload
});

export const getDefaultLimitsData = (assetId, timeseries, limitData) => ({
    type: actionTypes.GET_DEFAULT_LIMITS,
    payload: { assetId, timeseries, limitData }
});

export const setDefaultLimitsData = (payload) => ({
    type: actionTypes.SET_DEFAULT_LIMIT_DATA,
    payload
});

export const updateDefaultLimits = (data) => ({
    type: actionTypes.UPDATE_DEFAULT_LIMITS,
    data
});
